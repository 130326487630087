import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, RotateCcw, Volume2 } from 'lucide-react';

const ChordPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    // Using a more reliable sample audio source
    const audioUrl = "/home/gotho/PycharmProjects/server/appregiator/looperman-l-0671112-0080264-danke-heavy-metal-riff.wav";

    try {
      audioRef.current = new Audio(audioUrl);
      const audio = audioRef.current;
      audio.volume = volume;

      const handleCanPlay = () => {
        setDuration(audio.duration);
        setIsLoading(false);
        setError(null);
      };

      const handleLoadStart = () => {
        setIsLoading(true);
        setError(null);
      };

      const handleError = (e) => {
        let errorMessage = "Unable to load audio file";

        // More detailed error messages based on the error type
        if (e.target.error) {
          switch (e.target.error.code) {
            case e.target.error.MEDIA_ERR_ABORTED:
              errorMessage = "Audio loading was aborted";
              break;
            case e.target.error.MEDIA_ERR_NETWORK:
              errorMessage = "Network error while loading audio";
              break;
            case e.target.error.MEDIA_ERR_DECODE:
              errorMessage = "Audio decoding error";
              break;
            case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
              errorMessage = "Audio format not supported";
              break;
          }
        }

        setError(errorMessage);
        setIsLoading(false);
        console.error("Audio loading error:", e);
      };

      audio.addEventListener('loadstart', handleLoadStart);
      audio.addEventListener('canplay', handleCanPlay);
      audio.addEventListener('error', handleError);

      // Test if the audio can be loaded
      audio.load();

      return () => {
        audio.removeEventListener('loadstart', handleLoadStart);
        audio.removeEventListener('canplay', handleCanPlay);
        audio.removeEventListener('error', handleError);
        audio.pause();
        audio.src = '';
      };
    } catch (e) {
      setError("Failed to initialize audio player");
      setIsLoading(false);
      console.error("Audio initialization error:", e);
    }
  }, []);

  const togglePlay = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch(e => {
          setError("Playback error: " + (e.message || "Unable to play audio"));
          console.error("Playback error:", e);
          setIsPlaying(false);
        });
      }
    }
    setIsPlaying(!isPlaying);
  };

  const reset = () => {
    if (!audioRef.current) return;
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
    setCurrentTime(0);
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  useEffect(() => {
    if (!audioRef.current) return;

    const audio = audioRef.current;
    const updateTime = () => setCurrentTime(audio.currentTime);
    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
    };

    audio.addEventListener('timeupdate', updateTime);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('timeupdate', updateTime);
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);

  // Updated chord data to match the shorter audio
  const chordData = [
    {"start": 0, "end": 0.5, "chord_majmin": "C:maj"},
    {"start": 0.5, "end": 1, "chord_majmin": "G:maj"}
  ];

  if (isLoading) {
    return (
      <div className="p-6 bg-gray-100 rounded-lg max-w-3xl">
        <div className="flex items-center justify-center gap-2">
          <div className="w-4 h-4 border-2 border-blue-600 border-t-transparent rounded-full animate-spin" />
          <div className="text-gray-600">Loading audio...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-gray-100 rounded-lg max-w-3xl">
        <div className="text-center">
          <div className="text-red-600 mb-2">{error}</div>
          <div className="text-sm text-gray-500">
            Try refreshing the page or check your internet connection
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 rounded-lg max-w-3xl">
      <div className="flex items-center justify-between mb-6">
        <div className="flex gap-4 items-center">
          <button
            onClick={togglePlay}
            className="p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
            disabled={!audioRef.current}
          >
            {isPlaying ? <Pause size={24} /> : <Play size={24} />}
          </button>
          <button
            onClick={reset}
            className="p-3 bg-gray-600 text-white rounded-full hover:bg-gray-700 transition-colors"
            disabled={!audioRef.current}
          >
            <RotateCcw size={24} />
          </button>
          <div className="flex items-center gap-2">
            <Volume2 size={20} className="text-gray-600" />
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={volume}
              onChange={handleVolumeChange}
              className="w-24"
            />
          </div>
        </div>
        <div className="text-lg font-mono text-gray-700">
          {currentTime.toFixed(2)}s / {duration.toFixed(2)}s
        </div>
      </div>

      <div className="relative h-24 bg-white rounded-lg border border-gray-300 shadow-inner">
        <div
          className="absolute h-full bg-blue-100 transition-all duration-100"
          style={{
            width: duration ? `${(currentTime / duration) * 100}%` : '0%'
          }}
        />
        {chordData.map((chord, i) => (
          <div
            key={i}
            className="absolute h-full border-l border-gray-200"
            style={{
              left: `${(chord.start / chordData[chordData.length-1].end) * 100}%`,
            }}
          >
            <div className="absolute -top-6 -translate-x-1/2 text-sm font-medium text-gray-700">
              {chord.chord_majmin.split(':')[0]}
              {chord.chord_majmin.includes('min') ? 'm' : ''}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChordPlayer;