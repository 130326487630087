import React, { useState, useEffect } from 'react';

const TypewriterComponent = () => {
  const [currentText, setCurrentText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [typeIndex, setTypeIndex] = useState(0);
  const [responseQueue, setResponseQueue] = useState([]);

  const fetchNewResponse = async () => {
    try {
      const response = await fetch('http://gothos.biz:11435/api/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: "gothgen",
          prompt: "Generate 5 new gotho- words",
          stream: false
        })
      });

      const data = await response.json();
      return data.response;
    } catch (error) {
      console.error('Error fetching response:', error);
      return 'Error generating words...';
    }
  };

  const typeWriter = async (text) => {
    setIsTyping(true);
    setCurrentText('');
    setTypeIndex(0);

    const typeNextChar = () => {
      if (typeIndex < text.length) {
        setCurrentText(prev => prev + text[typeIndex]);
        setTypeIndex(prev => prev + 1);
      } else {
        setIsTyping(false);
      }
    };

    const intervalId = setInterval(typeNextChar, 50);
    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    const manageTypewriter = async () => {
      if (!isTyping && responseQueue.length === 0) {
        const newResponse = await fetchNewResponse();
        setResponseQueue([newResponse]);
      }

      if (!isTyping && responseQueue.length > 0) {
        const currentResponse = responseQueue[0];
        setResponseQueue(prev => prev.slice(1));
        typeWriter(currentResponse);
      }
    };

    const intervalId = setInterval(manageTypewriter, 100);
    return () => clearInterval(intervalId);
  }, [isTyping, responseQueue]);

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8 flex flex-col items-center justify-center">
      <div className="max-w-2xl w-full bg-gray-800 p-8 rounded-lg shadow-xl">
        <h2 className="text-3xl font-bold mb-6 text-purple-400">Gotho Word Generator</h2>
        <div className="min-h-48 bg-gray-700 p-6 rounded-lg">
          <p className="text-xl font-mono">
            {currentText}
            <span className="animate-pulse">|</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TypewriterComponent;