import React, { useState, useEffect } from 'react';
import { Loader, Trash2 } from 'lucide-react';

export const AudioMaster = () => {
  const [url, setUrl] = useState('');
  const [jobId, setJobId] = useState(null);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const baseUrl = 'https://gothos.biz/api';

  const handleProcess = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch(`${baseUrl}/master`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ input_url: url })
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error || 'Processing failed');

      setJobId(data.job_id);
      setStatus('processing');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${baseUrl}/job/${jobId}`, {
        method: 'DELETE',
        headers: { 'Accept': 'application/json' }
      });

      if (response.status === 204 || response.ok) {
        setJobId(null);
        setStatus(null);
        setUrl('');
      } else {
        const data = await response.json();
        throw new Error(data.error || 'Delete failed');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    let interval;
    if (jobId && status === 'processing') {
      interval = setInterval(async () => {
        try {
          const response = await fetch(`${baseUrl}/job/${jobId}`, {
            headers: { 'Accept': 'application/json' }
          });

          const data = await response.json();
          if (!response.ok) throw new Error(data.error || 'Status check failed');

          setStatus(data.status);
          if (data.status === 'completed' || data.status === 'failed') {
            clearInterval(interval);
          }
        } catch (err) {
          setError(err.message);
          clearInterval(interval);
        }
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [jobId, status]);

  return (
    <div className="w-full p-4 bg-white rounded-lg shadow-sm border">
      <h2 className="text-lg font-semibold mb-2">Audio Mastering</h2>
      <div className="space-y-4">
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter audio URL"
          disabled={loading}
          className="w-full p-2 border rounded"
        />

        <button
          onClick={handleProcess}
          disabled={!url || loading}
          className="w-full p-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
        >
          {loading ? (
            <div className="flex items-center justify-center">
              <Loader className="w-4 h-4 mr-2 animate-spin" />
              Processing...
            </div>
          ) : (
            'Master Audio'
          )}
        </button>

        {error && (
          <div className="p-2 text-red-500 bg-red-50 rounded">
            {error}
          </div>
        )}

        {jobId && status && (
          <div className="p-2 border rounded">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                {status === 'processing' && (
                  <Loader className="w-4 h-4 animate-spin text-blue-500" />
                )}
                <span className="capitalize">{status}</span>
              </div>
              <button
                onClick={handleDelete}
                className="p-1 text-red-500 hover:bg-red-50 rounded"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
            <div className="mt-2 text-sm text-gray-500">
              Job ID: {jobId}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AudioMaster;