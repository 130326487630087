import React from 'react';

const TemporaryLoginCredentials = ({ username, walletAddress, tempPassword }) => {
  return (
    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
      <h3 className="font-bold">Temporary Login Credentials</h3>
      <p>Please use these credentials to log in:</p>
      <div className="mt-2">
        <p><strong>Username:</strong> {username}</p>
        <p><strong>Wallet Address:</strong> {walletAddress}</p>
        {tempPassword && (
          <p><strong>Temporary Password:</strong> {tempPassword}</p>
        )}
      </div>
      <p className="text-sm mt-2 text-yellow-600">
        Make sure to save these credentials and change your password after logging in.
      </p>
    </div>
  );
};

export default TemporaryLoginCredentials;