import React, { useState, useEffect, useRef } from 'react';
import { Upload, Play, Pause } from 'lucide-react';

const PitchNoteVisualizer = ({ data, width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const padding = 40;

    // Clear canvas
    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = '#0f172a';
    ctx.fillRect(0, 0, width, height);

    if (data.length === 0) return;

    // Calculate scales
    const timeRange = data[data.length - 1].time - data[0].time;
    const minNote = Math.min(...data.map(d => d.note));
    const maxNote = Math.max(...data.map(d => d.note));
    const noteRange = maxNote - minNote;

    const scaleX = (width - 2 * padding) / timeRange;
    const scaleY = (height - 2 * padding) / noteRange;

    // Draw axes
    ctx.strokeStyle = '#a78bfa';
    ctx.beginPath();
    ctx.moveTo(padding, padding);
    ctx.lineTo(padding, height - padding);
    ctx.lineTo(width - padding, height - padding);
    ctx.stroke();

    // Draw notes
    ctx.strokeStyle = '#8b5cf6';
    ctx.lineWidth = 2;
    ctx.beginPath();
    data.forEach((point, i) => {
      const x = padding + (point.time - data[0].time) * scaleX;
      const y = height - padding - (point.note - minNote) * scaleY;

      if (i === 0) {
        ctx.moveTo(x, y);
      } else {
        ctx.lineTo(x, y);
      }
    });
    ctx.stroke();

    // Draw points
    data.forEach(point => {
      const x = padding + (point.time - data[0].time) * scaleX;
      const y = height - padding - (point.note - minNote) * scaleY;

      ctx.beginPath();
      ctx.arc(x, y, 3, 0, 2 * Math.PI);
      ctx.fillStyle = '#a78bfa';
      ctx.fill();
    });

    // Draw axis labels
    ctx.fillStyle = '#a78bfa';
    ctx.font = '12px sans-serif';
    ctx.textAlign = 'right';

    // Y-axis labels
    for (let i = 0; i <= noteRange; i += 2) {
      const y = height - padding - i * scaleY;
      ctx.fillText((minNote + i).toString(), padding - 5, y + 4);
    }

    // X-axis labels
    ctx.textAlign = 'center';
    for (let i = 0; i <= timeRange; i += Math.ceil(timeRange / 5)) {
      const x = padding + i * scaleX;
      ctx.fillText(i.toFixed(1) + 's', x, height - padding + 20);
    }
  }, [data, width, height]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className="rounded-lg"
    />
  );
};

const PitchArpeggioVisualizer = () => {
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const gainNodeRef = useRef(null);
  const audioBufferRef = useRef(null);

  const [speed, setSpeed] = useState(20);
  const [repetitions, setRepetitions] = useState(5);
  const [volume, setVolume] = useState(0);
  const [data, setData] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioFile, setAudioFile] = useState(null);
  const [audioFileName, setAudioFileName] = useState('');
  const [csvData, setCSVData] = useState([]);
  const [csvFileName, setCSVFileName] = useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [selectedNote, setSelectedNote] = useState(null);

  useEffect(() => {
    const initAudioContext = async () => {
      try {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        gainNodeRef.current = audioContextRef.current.createGain();
        gainNodeRef.current.connect(audioContextRef.current.destination);
      } catch (err) {
        setError('Failed to initialize audio context: ' + err.message);
      }
    };
    initAudioContext();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (gainNodeRef.current) {
      gainNodeRef.current.gain.value = Math.pow(10, volume / 20);
    }
  }, [volume]);

  const handleAudioFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      if (!file.type.startsWith('audio/')) {
        throw new Error('Please upload an audio file');
      }

      setAudioFileName(file.name);
      setAudioFile(file);
      setError('');

      const arrayBuffer = await file.arrayBuffer();
      audioBufferRef.current = await audioContextRef.current.decodeAudioData(arrayBuffer);
    } catch (err) {
      setError('Error loading audio file: ' + err.message);
      setAudioFile(null);
      setAudioFileName('');
    }
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      if (!file.name.endsWith('.csv')) {
        throw new Error('Please upload a CSV file');
      }

      setCSVFileName(file.name);
      setError('');

      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const text = e.target.result;
          const lines = text.split('\n');
          const headers = lines[0].split(',');

          const parsedData = lines.slice(1)
            .filter(line => line.trim())
            .map(line => {
              const values = line.split(',');
              return {
                start: parseFloat(values[0]),
                end: parseFloat(values[1]),
                note_name: values[2],
                midi_note: parseInt(values[3])
              };
            });

          setCSVData(parsedData);

          const transformedData = parsedData.map(note => ({
            time: note.start,
            note: note.midi_note - 60,
            noteName: note.note_name
          }));
          setData(transformedData);
        } catch (err) {
          setError('Error parsing CSV: ' + err.message);
          setCSVData([]);
          setData([]);
        }
      };
      reader.readAsText(file);
    } catch (err) {
      setError(err.message);
      setCSVFileName('');
    }
  };

  const generateArpeggio = async () => {
    if (!audioBufferRef.current || !csvData.length) {
      setError('Please upload both audio and CSV files first');
      return;
    }

    setProcessing(true);
    setError('');

    try {
      const offlineContext = new OfflineAudioContext(
        2,
        audioBufferRef.current.length * repetitions,
        audioBufferRef.current.sampleRate
      );

      let currentTime = 0;

      for (let rep = 0; rep < repetitions; rep++) {
        for (let i = 0; i < csvData.length; i++) {
          const note = csvData[i];
          const source = offlineContext.createBufferSource();
          const gainNode = offlineContext.createGain();

          source.buffer = audioBufferRef.current;
          source.detune.value = (note.midi_note - 60) * 100;

          source.connect(gainNode);
          gainNode.connect(offlineContext.destination);

          source.start(currentTime);

          if (i < csvData.length - 1) {
            currentTime += (csvData[i + 1].start - note.start) / speed;
          }
        }
      }

      const renderedBuffer = await offlineContext.startRendering();
      audioBufferRef.current = renderedBuffer;
      setProcessing(false);
    } catch (err) {
      setError('Error generating arpeggio: ' + err.message);
      setProcessing(false);
    }
  };

  const togglePlayback = () => {
    if (isPlaying) {
      if (sourceNodeRef.current) {
        sourceNodeRef.current.stop();
        sourceNodeRef.current = null;
      }
      setIsPlaying(false);
      return;
    }

    if (!audioBufferRef.current) {
      setError('No audio available to play');
      return;
    }

    try {
      sourceNodeRef.current = audioContextRef.current.createBufferSource();
      sourceNodeRef.current.buffer = audioBufferRef.current;
      sourceNodeRef.current.connect(gainNodeRef.current);

      sourceNodeRef.current.onended = () => {
        setIsPlaying(false);
        sourceNodeRef.current = null;
      };

      sourceNodeRef.current.start();
      setIsPlaying(true);
    } catch (err) {
      setError('Error playing audio: ' + err.message);
      setIsPlaying(false);
    }
  };

 return (
    <div className="bg-gradient-to-br from-indigo-900 to-purple-900 min-h-screen p-8 text-white">
      <div className="max-w-4xl mx-auto bg-black bg-opacity-30 rounded-xl backdrop-blur-lg p-8 shadow-2xl">
        <h1 className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
          Pitch Arpeggio Visualizer
        </h1>

        <div className="space-y-8">
          {/* File Upload Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium mb-2 text-purple-300">Audio File</label>
              <div className="relative">
                <input
                  type="file"
                  accept="audio/*"
                  onChange={handleAudioFileUpload}
                  className="hidden"
                  id="audio-upload"
                />
                <button
                  onClick={() => document.getElementById('audio-upload').click()}
                  className="w-full px-4 py-2 bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors flex items-center justify-center space-x-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-black"
                >
                  <Upload className="w-4 h-4" />
                  <span>{audioFileName || 'Upload Audio'}</span>
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2 text-purple-300">CSV File</label>
              <div className="relative">
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleCSVUpload}
                  className="hidden"
                  id="csv-upload"
                />
                <button
                  onClick={() => document.getElementById('csv-upload').click()}
                  className="w-full px-4 py-2 bg-purple-600 hover:bg-purple-700 rounded-lg transition-colors flex items-center justify-center space-x-2 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-black"
                >
                  <Upload className="w-4 h-4" />
                  <span>{csvFileName || 'Upload CSV'}</span>
                </button>
              </div>
            </div>
          </div>

          {/* Visualization */}
          <div className="bg-black bg-opacity-50 rounded-lg p-4">
            <PitchNoteVisualizer data={data} width={800} height={300} />
          </div>

          {/* Controls */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <label className="block text-sm font-medium mb-2 text-purple-300">
                Speed Factor: {speed}
              </label>
              <input
                type="range"
                min="1"
                max="50"
                value={speed}
                onChange={(e) => setSpeed(Number(e.target.value))}
                className="w-full h-2 bg-purple-900 rounded-lg appearance-none cursor-pointer accent-purple-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2 text-purple-300">
                Repetitions: {repetitions}
              </label>
              <input
                type="range"
                min="1"
                max="10"
                value={repetitions}
                onChange={(e) => setRepetitions(Number(e.target.value))}
                className="w-full h-2 bg-purple-900 rounded-lg appearance-none cursor-pointer accent-purple-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2 text-purple-300">
                Volume (dB): {volume}
              </label>
              <input
                type="range"
                min="-20"
                max="6"
                value={volume}
                onChange={(e) => setVolume(Number(e.target.value))}
                className="w-full h-2 bg-purple-900 rounded-lg appearance-none cursor-pointer accent-purple-500"
              />
            </div>
          </div>

          {/* Error Display */}
          {error && (
            <div className="bg-red-900 bg-opacity-50 text-red-200 p-4 rounded-lg text-sm">
              {error}
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex space-x-4">
            <button
              onClick={generateArpeggio}
              disabled={processing || !audioFile || !csvData.length}
              className={`flex-1 px-6 py-3 rounded-lg flex items-center justify-center space-x-2 ${
                processing || !audioFile || !csvData.length
                  ? 'bg-gray-700 cursor-not-allowed'
                  : 'bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700'
              } transition-all focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-black`}
            >
              {processing ? 'Generating...' : 'Generate Arpeggio'}
            </button>

            <button
              onClick={togglePlayback}
              disabled={processing || !audioBufferRef.current}
              className={`flex-1 px-6 py-3 rounded-lg flex items-center justify-center space-x-2 ${
                processing || !audioBufferRef.current
                  ? 'bg-gray-700 cursor-not-allowed'
                  : 'bg-gradient-to-r from-indigo-600 to-blue-600 hover:from-indigo-700 hover:to-blue-700'
              } transition-all focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-black`}
            >
              {isPlaying ? <Pause className="w-5 h-5" /> : <Play className="w-5 h-5" />}
              <span>{isPlaying ? 'Stop' : 'Play'}</span>
            </button>
          </div>

          {/* Stats */}
          <div className="grid grid-cols-3 gap-4 text-sm">
            <div className="bg-purple-900 bg-opacity-30 p-3 rounded-lg">
              <p className="font-medium text-purple-300">Total Notes</p>
              <p className="text-white">{data.length}</p>
            </div>
            <div className="bg-purple-900 bg-opacity-30 p-3 rounded-lg">
              <p className="font-medium text-purple-300">Note Range</p>
              <p className="text-white">
                {data.length
                  ? `${Math.min(...data.map(d => d.note))} to ${Math.max(...data.map(d => d.note))}`
                  : '0 to 0'
                }
              </p>
            </div>
            <div className="bg-purple-900 bg-opacity-30 p-3 rounded-lg">
              <p className="font-medium text-purple-300">Duration</p>
              <p className="text-white">
                {data.length > 0
                  ? `${(data[data.length - 1].time - data[0].time).toFixed(2)}s`
                  : '0.00s'
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PitchArpeggioVisualizer;