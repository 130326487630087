import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, SkipBack, SkipForward, Scissors, Download, Save } from 'lucide-react';

const VideoTimelineEditor = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [inPoint, setInPoint] = useState(0);
  const [outPoint, setOutPoint] = useState(100);
  const [isDragging, setIsDragging] = useState(false);
  const videoRef = useRef(null);
  const timelineRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration || 0);
      setOutPoint(videoRef.current.duration || 100);
    }
  }, [videoRef?.current?.duration]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const frames = Math.floor((timeInSeconds % 1) * 24); // Assuming 24fps
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
  };

  const handleTimelineClick = (e) => {
    if (!timelineRef.current) return;
    const rect = timelineRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = x / rect.width;
    const newTime = percentage * duration;
    setCurrentTime(newTime);
    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
    }
  };

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleFrameStep = (forward = true) => {
    if (videoRef.current) {
      const frameTime = 1/24; // Assuming 24fps
      const newTime = currentTime + (forward ? frameTime : -frameTime);
      videoRef.current.currentTime = Math.max(0, Math.min(duration, newTime));
    }
  };

  return (
    <div className="w-full max-w-4xl bg-zinc-900 text-white rounded-lg shadow-xl p-6">
      {/* Video Preview */}
      <div className="relative bg-black rounded-lg overflow-hidden mb-4">
        <video
          ref={videoRef}
          className="w-full aspect-video object-contain"
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={() => setDuration(videoRef.current.duration)}
        >
          <source src="/api/placeholder/640/360" type="video/mp4" />
        </video>
      </div>

      {/* Timeline Section */}
      <div className="space-y-3">
        {/* Timecode Display */}
        <div className="flex justify-between text-sm font-mono text-zinc-400">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>

        {/* Timeline Scrubber */}
        <div className="relative">
          <div
            ref={timelineRef}
            className="h-24 bg-zinc-800 rounded-md cursor-pointer relative overflow-hidden"
            onClick={handleTimelineClick}
          >
            {/* Timeline Grid */}
            <div className="absolute inset-0 bg-grid opacity-10" />

            {/* Selected Range */}
            <div
              className="absolute h-full bg-blue-600/30 backdrop-blur-sm"
              style={{
                left: `${(inPoint / duration) * 100}%`,
                width: `${((outPoint - inPoint) / duration) * 100}%`
              }}
            />

            {/* Playhead */}
            <div
              className="absolute top-0 w-px h-full bg-red-500 shadow-lg shadow-red-500/50"
              style={{ left: `${(currentTime / duration) * 100}%` }}
            >
              <div className="w-3 h-3 -ml-1.5 bg-red-500 rounded-full" />
            </div>
          </div>
        </div>

        {/* Controls */}
        <div className="flex items-center justify-center gap-2 pt-2">
          <button
            className="p-2 rounded-lg hover:bg-zinc-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onClick={() => handleFrameStep(false)}
          >
            <SkipBack className="w-5 h-5" />
          </button>

          <button
            className="p-3 rounded-lg bg-zinc-700 hover:bg-zinc-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onClick={handlePlayPause}
          >
            {isPlaying ?
              <Pause className="w-6 h-6" /> :
              <Play className="w-6 h-6" />
            }
          </button>

          <button
            className="p-2 rounded-lg hover:bg-zinc-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onClick={() => handleFrameStep(true)}
          >
            <SkipForward className="w-5 h-5" />
          </button>

          <div className="w-px h-8 bg-zinc-700 mx-2" />

          <button
            className="flex flex-col items-center p-2 rounded-lg hover:bg-zinc-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onClick={() => setInPoint(currentTime)}
          >
            <Scissors className="w-5 h-5" />
            <span className="text-xs mt-1">In</span>
          </button>

          <button
            className="flex flex-col items-center p-2 rounded-lg hover:bg-zinc-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onClick={() => setOutPoint(currentTime)}
          >
            <Scissors className="w-5 h-5" />
            <span className="text-xs mt-1">Out</span>
          </button>

          <div className="w-px h-8 bg-zinc-700 mx-2" />

          <button
            className="p-2 rounded-lg hover:bg-zinc-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <Save className="w-5 h-5" />
          </button>

          <button
            className="p-2 rounded-lg hover:bg-zinc-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <Download className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoTimelineEditor;