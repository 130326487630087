import React, { useState, useEffect } from 'react';

const TestLinksComponent = () => {
  const [words, setWords] = useState('');
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [timeUntilNext, setTimeUntilNext] = useState(3600);

  const generateWords = async () => {
    try {
      const response = await fetch('https://gothos.biz/api/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ test: 'give me some gotho words' })
      });

      const data = await response.json();
      setWords(data.choices[0].message.content);
      setLastUpdate(new Date());
    } catch (err) {
      console.error(err);
      setWords('Error fetching words. Will retry in 1 hour.');
    }
  };

  useEffect(() => {
    generateWords();
    const interval = setInterval(generateWords, 30600000);
    const countdownInterval = setInterval(() => {
      setTimeUntilNext(prev => prev > 0 ? prev - 1 : 3600);
    }, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(countdownInterval);
    };
  }, []);

  return (
    <div className="p-4">
      <div>Next update: {Math.floor(timeUntilNext / 60)}:{(timeUntilNext % 60).toString().padStart(2, '0')}</div>
      <div className="mt-4 p-4 bg-gray-100 rounded">{words}</div>
      <div className="mt-2 text-sm">Updated: {lastUpdate.toLocaleTimeString()}</div>
    </div>
  );
};

export default TestLinksComponent;