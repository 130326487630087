import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DeviceController } from './deviceController';

const SpaceVisualization = () => {
  // Constants
  const STAR_COUNT = 2000;
  const TRAVEL_SPEED = 0.7;
  const INITIAL_USERS = [
    { username: 'Alice', walletAddress: '0x1234...5678' },
    { username: 'Bob', walletAddress: '0xABCD...EFGH' },
    { username: 'Charlie', walletAddress: '0x9876...5432' },
  ];

  // Refs
  const mountRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const controlsRef = useRef(null);
  const deviceControllerRef = useRef(null);
  const starsRef = useRef(null);
  const fleetRef = useRef(null);
  const timeRef = useRef(0);
  const galleryPlaneRef = useRef(null);
  const raycasterRef = useRef(new THREE.Raycaster());
  const mouseRef = useRef(new THREE.Vector2());

  // State
  const [users, setUsers] = useState(INITIAL_USERS);
  const [controlMode, setControlMode] = useState('touch');
  const [showGallery, setShowGallery] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [galleryItems, setGalleryItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Gallery interaction handler
  const handleGalleryInteraction = useCallback((event) => {
    if (!showGallery || !cameraRef.current) return;

    const rect = event.target.getBoundingClientRect();
    mouseRef.current.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouseRef.current.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    raycasterRef.current.setFromCamera(mouseRef.current, cameraRef.current);

    if (galleryPlaneRef.current) {
      const intersects = raycasterRef.current.intersectObjects(
        galleryPlaneRef.current.children,
        true
      );

      if (intersects.length > 0) {
        const item = intersects[0].object.userData.galleryItem;
        if (item) {
          setSelectedItem(item);
        }
      }
    }
  }, [showGallery]);

  // Motion access request handler
  const requestMotionAccess = useCallback(async () => {
    if (typeof DeviceOrientationEvent !== 'undefined' &&
        typeof DeviceOrientationEvent.requestPermission === 'function') {
      try {
        const permission = await DeviceOrientationEvent.requestPermission();
        if (permission === 'granted') {
          setControlMode('motion');
          deviceControllerRef.current?.connect();
        }
      } catch (error) {
        console.error('Error requesting motion permission:', error);
      }
    } else {
      setControlMode('motion');
      deviceControllerRef.current?.connect();
    }
  }, []);

  // Fetch gallery data
  const fetchGalleryItems = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(`/api/gallery/main?page=${page}&per_page=10`);
      const data = await response.json();

      if (!response.ok) throw new Error(data.error || 'Failed to fetch gallery');

      setGalleryItems(prev => page === 1 ? data.items : [...prev, ...data.items]);
      setHasMore(page < data.pagination.total_pages);
    } catch (err) {
      setError(err.message);
      console.error('Gallery fetch error:', err);
    } finally {
      setLoading(false);
    }
  }, [page]);

  // Ship creation function
  const createShip = useCallback((user, index) => {
    const shipGroup = new THREE.Group();

    // Ship body
    const shipGeometry = new THREE.ConeGeometry(2, 6, 6);
    const shipMaterial = new THREE.MeshPhongMaterial({
      color: new THREE.Color().setHSL(index * 0.1, 1, 0.5),
      emissive: new THREE.Color().setHSL(index * 0.1, 1, 0.2),
      emissiveIntensity: 0.5,
    });
    const shipMesh = new THREE.Mesh(shipGeometry, shipMaterial);
    shipMesh.rotation.x = Math.PI / 2;

    // Engine glow
    const glowGeometry = new THREE.SphereGeometry(1, 8, 8);
    const glowMaterial = new THREE.MeshBasicMaterial({
      color: 0x00ffff,
      transparent: true,
      opacity: 0.5,
    });
    const glow = new THREE.Mesh(glowGeometry, glowMaterial);
    glow.position.z = -3;

    shipGroup.add(shipMesh);
    shipGroup.add(glow);

    // Random initial position
    shipGroup.position.set(
      (Math.random() - 0.5) * 100,
      (Math.random() - 0.5) * 100,
      (Math.random() - 0.5) * 100
    );

    // Movement properties
    shipGroup.userData.velocity = new THREE.Vector3(
      (Math.random() - 0.5) * 0.2,
      (Math.random() - 0.5) * 0.2,
      (Math.random() - 0.5) * 0.2
    );

    return shipGroup;
  }, []);

  // Gallery plane creation
  const createGalleryPlane = useCallback(() => {
    const planeGeometry = new THREE.PlaneGeometry(50, 30);
    const planeMaterial = new THREE.MeshBasicMaterial({
      color: 0x222222,
      transparent: true,
      opacity: 0.8
    });
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.position.set(0, 0, -50);

    // Create grid of gallery items
    galleryItems.forEach((item, index) => {
      const texture = new THREE.TextureLoader().load(item.image_url);
      const itemGeometry = new THREE.PlaneGeometry(8, 8);
      const itemMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true
      });
      const itemMesh = new THREE.Mesh(itemGeometry, itemMaterial);

      const col = index % 3;
      const row = Math.floor(index / 3);
      itemMesh.position.set(
        -20 + (col * 10),
        10 - (row * 10),
        0.1
      );

      itemMesh.userData.galleryItem = item;
      plane.add(itemMesh);
    });

    return plane;
  }, [galleryItems]);

  // Load gallery data when showing gallery
  useEffect(() => {
    if (showGallery) {
      fetchGalleryItems();
    }
  }, [showGallery, fetchGalleryItems]);

  // Scene setup and animation
  useEffect(() => {
    if (!mountRef.current) return;

    // Scene setup
    const scene = new THREE.Scene();
    sceneRef.current = scene;
    scene.fog = new THREE.FogExp2(0x000000, 0.001);

    // Camera setup
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.set(0, 30, 100);
    camera.lookAt(0, 0, 0);
    cameraRef.current = camera;

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({
      canvas: mountRef.current,
      antialias: true,
      powerPreference: "high-performance",
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    rendererRef.current = renderer;

    // Controls setup
    const orbitControls = new OrbitControls(camera, renderer.domElement);
    orbitControls.enableDamping = true;
    orbitControls.dampingFactor = 0.05;
    orbitControls.enableZoom = true;
    orbitControls.enablePan = false;
    orbitControls.rotateSpeed = 0.5;
    orbitControls.touchRotateSpeed = 0.5;
    controlsRef.current = orbitControls;

    const deviceController = new DeviceController(camera);
    deviceControllerRef.current = deviceController;

    // Create stars
    const starGeometry = new THREE.BufferGeometry();
    const starPositions = new Float32Array(STAR_COUNT * 3);
    const starColors = new Float32Array(STAR_COUNT * 3);

    for (let i = 0; i < STAR_COUNT; i++) {
      const i3 = i * 3;
      starPositions[i3] = (Math.random() - 0.5) * 1000;
      starPositions[i3 + 1] = (Math.random() - 0.5) * 1000;
      starPositions[i3 + 2] = (Math.random() - 0.5) * 1000;

      const color = new THREE.Color().setHSL(Math.random(), 0.5, 0.8);
      starColors[i3] = color.r;
      starColors[i3 + 1] = color.g;
      starColors[i3 + 2] = color.b;
    }

    starGeometry.setAttribute('position', new THREE.BufferAttribute(starPositions, 3));
    starGeometry.setAttribute('color', new THREE.BufferAttribute(starColors, 3));

    const starMaterial = new THREE.PointsMaterial({
      size: 2,
      vertexColors: true,
      transparent: true,
      opacity: 0.8,
    });

    const stars = new THREE.Points(starGeometry, starMaterial);
    scene.add(stars);
    starsRef.current = stars;

    // Lighting
    const ambientLight = new THREE.AmbientLight(0x404040);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(1, 1, 1);
    scene.add(directionalLight);

    // Create fleet
    const fleet = new THREE.Group();
    users.forEach((user, index) => {
      const ship = createShip(user, index);
      fleet.add(ship);
    });
    scene.add(fleet);
    fleetRef.current = fleet;

    // Add gallery plane
    const galleryPlane = createGalleryPlane();
    scene.add(galleryPlane);
    galleryPlaneRef.current = galleryPlane;
    galleryPlane.visible = showGallery;

    // Animation loop
    const animate = () => {
      timeRef.current += 0.01;

      // Update controls based on mode
      if (controlMode === 'motion') {
        deviceControllerRef.current?.update();
      } else if (controlMode === 'touch') {
        controlsRef.current?.update();
      }

      // Update stars
      const starPositions = starsRef.current.geometry.attributes.position.array;
      for (let i = 0; i < starPositions.length; i += 3) {
        starPositions[i + 2] -= TRAVEL_SPEED;
        if (starPositions[i + 2] < -500) {
          starPositions[i + 2] = 500;
        }
      }
      starsRef.current.geometry.attributes.position.needsUpdate = true;

      // Update fleet
      if (fleetRef.current) {
        fleetRef.current.children.forEach((ship) => {
          ship.position.add(ship.userData.velocity);

          // Boundary check
          if (Math.abs(ship.position.x) > 100) ship.userData.velocity.x *= -1;
          if (Math.abs(ship.position.y) > 100) ship.userData.velocity.y *= -1;
          if (Math.abs(ship.position.z) > 100) ship.userData.velocity.z *= -1;

          const glow = ship.children[1];
          glow.scale.setScalar(1 + Math.sin(timeRef.current * 3) * 0.2);
        });
      }

      // Update gallery
      if (showGallery && galleryPlaneRef.current && cameraRef.current) {
        galleryPlaneRef.current.lookAt(cameraRef.current.position);
      }

      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    animate();

    // Handle window resize
    const handleResize = () => {
      if (cameraRef.current && rendererRef.current) {
        cameraRef.current.aspect = window.innerWidth / window.innerHeight;
        cameraRef.current.updateProjectionMatrix();
        rendererRef.current.setSize(window.innerWidth, window.innerHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    // Update control mode
    if (controlMode === 'motion') {
      orbitControls.enabled = false;
      deviceController.connect();
    } else {
      orbitControls.enabled = true;
      deviceController.disconnect();
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      deviceController.disconnect();
      renderer.dispose();
      orbitControls.dispose();
      scene.clear();
    };
  }, [users, createShip, controlMode, showGallery, createGalleryPlane]);

  // Component render
  return (
    <div className="w-full h-screen relative">
      <canvas
        ref={mountRef}
        className="w-full h-full"
        onClick={handleGalleryInteraction}
      />

      {showGallery && (
        <div className="absolute top-4 left-4 text-white">
          {loading && (
            <div className="bg-black bg-opacity-50 p-2 rounded mb-2">
              Loading gallery items...
            </div>
          )}
          {error && (
            <div className="bg-red-900 bg-opacity-50 p-2 rounded mb-2">
              Error: {error}
            </div>
          )}
          {hasMore && !loading && (
            <button
              onClick={() => setPage(p => p + 1)}
              className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-700 transition-colors"
            >
              Load More
            </button>
          )}
        </div>
      )}

      <div className="absolute bottom-4 left-4 text-white space-y-2">
        <div className="bg-black bg-opacity-50 p-2 rounded">
          <div>Fleet Size: {users.length}</div>
          <div>Control Mode: {controlMode}</div>
        </div>

        <div className="flex flex-col gap-2">
          <button
            onClick={() => {
              setControlMode('touch');
              deviceControllerRef.current?.disconnect();
            }}
            className={`px-4 py-2 rounded ${
              controlMode === 'touch' ? 'bg-blue-600' : 'bg-blue-400'
            }`}
          >
            Touch Controls
          </button>

          <button
            onClick={requestMotionAccess}
            className={`px-4 py-2 rounded ${
              controlMode === 'motion' ? 'bg-blue-600' : 'bg-blue-400'
            }`}
          >
            Motion Controls
          </button>

          <button
            onClick={() => setShowGallery(!showGallery)}
            className={`px-4 py-2 rounded ${
              showGallery ? 'bg-blue-600' : 'bg-blue-400'
            }`}
          >
            {showGallery ? 'Hide Gallery' : 'Show Gallery'}
          </button>
        </div>
      </div>

      {selectedItem && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-80 p-6 rounded-lg text-white max-w-md w-full">
          <h3 className="text-2xl font-bold mb-2">{selectedItem.title}</h3>
          <p className="text-gray-300 mb-4">{selectedItem.description}</p>
          <div className="mb-4">
            <img src={selectedItem.image_url} alt={selectedItem.title} className="w-full h-64 object-cover rounded" />
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4 text-sm">
            <div>
              <p className="text-gray-400">Created by</p>
              <p className="font-semibold">{selectedItem.creator}</p>
            </div>
            <div>
              <p className="text-gray-400">Created on</p>
              <p className="font-semibold">{new Date(selectedItem.created_at).toLocaleDateString()}</p>
            </div>
            <div>
              <p className="text-gray-400">Comments</p>
              <p className="font-semibold">{selectedItem.comment_count}</p>
            </div>
            <div>
              <p className="text-gray-400">Views</p>
              <p className="font-semibold">{selectedItem.view_count}</p>
            </div>
          </div>
          <button
            onClick={() => setSelectedItem(null)}
            className="w-full py-2 bg-blue-600 hover:bg-blue-700 rounded transition-colors"
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default SpaceVisualization;