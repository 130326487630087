import { useState, useEffect } from 'react'

export default function TestAPI() {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch('https://gothos.biz/api/test')
      .then(res => res.json())
      .then(setData)
      .catch(setError)
  }, [])

  if (error) return <div>Error: {error.message}</div>
  if (!data) return <div>Loading...</div>

  return <div>{data.message}</div>
}
