import React, { useState, useRef } from 'react';
import { useFileLink } from "./FileContext";

const CHUNK_SIZE = 1024 * 1024 * 5; // 5MB chunks

const FileUploader = () => {
  const { setFileLink } = useFileLink();
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const fileInputRef = useRef(null);
  const abortControllerRef = useRef(null);

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setError('');
    setProgress(0);

    if (selectedFile) {
      setFile(selectedFile);
      setUploadStatus(`File selected: ${selectedFile.name} (${formatFileSize(selectedFile.size)})`);
    } else {
      setFile(null);
      setUploadStatus('');
    }
  };

  const uploadChunk = async (chunk, chunkIndex, totalChunks) => {
    const formData = new FormData();
    formData.append('file', chunk, file.name);
    formData.append('chunkIndex', chunkIndex);
    formData.append('totalChunks', totalChunks);

    const response = await fetch('https://gothos.biz/api/upload', {
      method: 'POST',
      body: formData,
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'X-Chunk-Index': chunkIndex,
        'X-Total-Chunks': totalChunks,
        'X-File-Name': file.name
      },
      signal: abortControllerRef.current?.signal
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Upload failed');
    }

    return response.json();
  };

  const uploadFile = async () => {
    if (!file) {
      setError('Please select a file first');
      return;
    }

    try {
      setUploadStatus('Preparing upload...');
      setError('');
      setProgress(0);

      abortControllerRef.current = new AbortController();
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      let uploadedChunks = 0;

      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const start = chunkIndex * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);

        setUploadStatus(`Uploading chunk ${chunkIndex + 1} of ${totalChunks}...`);
        const result = await uploadChunk(chunk, chunkIndex, totalChunks);
        uploadedChunks++;

        const newProgress = (uploadedChunks / totalChunks) * 100;
        setProgress(newProgress);

if (chunkIndex === totalChunks - 1 && result.url) {
  console.log("Setting file link to:", result.url); // Debug log
  setUploadStatus('Upload successful');
  setFileLink(result.url);
  return;
}
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        setError('Upload cancelled');
      } else {
        setError(error.message);
      }
      setUploadStatus('');
    }
  };

  const cancelUpload = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  return (
    <div className="space-y-6">
      <div className="p-6 bg-white rounded-lg shadow-sm border border-gray-200">
        <div className="flex items-center gap-4 mb-4">
          <button
            onClick={() => fileInputRef.current.click()}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Choose File
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
          />
          <span className="text-gray-600 text-sm">
            {file ? file.name : 'No file chosen'}
          </span>
        </div>

        <div className="flex gap-3">
          <button
            onClick={uploadFile}
            disabled={!file || progress > 0}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Upload
          </button>
          {progress > 0 && progress < 100 && (
            <button
              onClick={cancelUpload}
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              Cancel
            </button>
          )}
        </div>

        {progress > 0 && (
          <div className="mt-4">
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="text-sm text-gray-600 mt-1">
              {Math.round(progress)}% uploaded
            </div>
          </div>
        )}

        {uploadStatus && (
          <div className="mt-4 p-3 bg-blue-50 text-blue-700 rounded-md border border-blue-100">
            {uploadStatus}
          </div>
        )}

        {error && (
          <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-md border border-red-100">
            {error}
          </div>
        )}
      </div>

      <FilePreview />
    </div>
  );
};

const FilePreview = () => {
  const { fileLink } = useFileLink();

  if (!fileLink) return null;

  return (
    <div className="p-6 bg-white rounded-lg shadow-sm border border-gray-200">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Uploaded File</h3>
      <a
        href={fileLink}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:text-blue-800 break-all"
      >
        {fileLink}
      </a>
    </div>
  );
};

export default FileUploader;