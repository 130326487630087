import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import { AuthProvider } from './context/AuthContext';
import TypewriterComponent from "./components/WordGenerator";

function App() {
  const [apiStatus, setApiStatus] = useState('checking');

  useEffect(() => {
    const checkAPI = async () => {
      try {
        const response = await fetch('/api/test');
        if (!response.ok) throw new Error('API unavailable');
        await response.json();
        setApiStatus('available');
      } catch (error) {
        console.error('API Error:', error);
        setApiStatus('unavailable');
      }
    };

    checkAPI();
  }, []);

  if (apiStatus === 'checking') {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <h1 className="text-3xl font-bold mb-4">Gothos.biz</h1>
          <p>Loading...</p>
        </div>

      </div>
    );
  }

  if (apiStatus === 'unavailable') {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <h1 className="text-3xl font-bold mb-4">System Unavailable</h1>
          <p className="text-red-600">Unable to connect to the server. Please try again later.</p>
        </div>
      </div>
    );
  }

  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-gray-100">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );

}

export default App;