import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthContext from "../context/AuthContext";



import TypewriterComponent from "./WordGenerator";
import TestLinksComponent from "./TestLinksComponent";

const Dashboard = () => {
  const { user, login, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // State management
  const [balance, setBalance] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);

  // Helper functions
  const calculateTotal = () => {
    return (quantity * 0.5).toFixed(2);
  };

  // API calls
  const fetchBalance = async () => {
    try {
      const response = await fetch(`/api/get_balance/${user?.wallet_address}`);
      if (!response.ok) throw new Error('Balance fetch failed');
      const data = await response.json();
      setBalance(data.balance * 2); // Convert dollars to GOTHOS
    } catch (error) {
      console.error('Error fetching balance:', error);
      setError('Failed to fetch balance');
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setPasswordError('');
    setChangeSuccess(false);

    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    if (newPassword.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      return;
    }

    try {
      const response = await fetch('/api/change_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ new_password: newPassword }),
      });

      if (!response.ok) throw new Error('Password change failed');

      const data = await response.json();
      await login(data);
      setNewPassword('');
      setConfirmPassword('');
      setChangeSuccess(true);
    } catch (error) {
      setPasswordError(error.message);
    }
  };

  const handleBuyMore = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('/api/refill_init-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ quantity })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to initialize checkout');
      }

      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (error) {
      console.error('Checkout error:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
      setShowConfirm(false);
    }
  };

  const checkPaymentStatus = async () => {
    const sessionId = searchParams.get('session_id');
    if (!sessionId) return;

    try {
      const response = await fetch(`/api/verify-payment?session_id=${sessionId}`);
      if (!response.ok) throw new Error('Failed to verify payment');

      const data = await response.json();
      if (data.status === 'complete') {
        setPaymentStatus({
          type: 'success',
          message: `Payment successful! ${data.quantity * 2} GOTHOS have been added to your balance.`
        });
        setBalance(data.current_balance * 2);
      }
    } catch (error) {
      console.error('Payment verification error:', error);
      setPaymentStatus({
        type: 'error',
        message: 'Error verifying payment. Please contact support if your balance is not updated.'
      });
    }
  };

  // Effects
  useEffect(() => {
    if (user?.wallet_address) {
      fetchBalance();
    }
  }, [user?.wallet_address]);

  useEffect(() => {
    if (searchParams.get('session_id')) {
      checkPaymentStatus();
    }
  }, [searchParams]);

  if (!user) {
    navigate('/');
    return null;
  }

  return (
    <div className="min-h-screen p-8 bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="max-w-4xl mx-auto">
        {/* Payment Status Message */}
        {paymentStatus && (
          <div className={`mb-6 p-4 rounded-lg ${
            paymentStatus.type === 'success' ? 'bg-green-100 text-green-700' :
            'bg-red-100 text-red-700'
          } border-l-4`}>
            {paymentStatus.message}
          </div>
        )}

        {/* Main Dashboard Content */}
        <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
          <div className="flex justify-between items-center mb-8">
            <div>
              <h1 className="text-2xl font-bold text-gray-800">Welcome, {user.username}!</h1>
              <p className="text-gray-500 mt-1">Manage your GOTHOS account</p>
            </div>
            <button
              onClick={() => {
                logout();
                navigate('/');
              }}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              Logout
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Wallet Section */}
            <div className="space-y-6">
              <div className="p-6 border rounded-xl bg-purple-50">
                <h2 className="text-lg font-semibold mb-4">Your Wallet</h2>
                <div className="space-y-3">
                  <p className="text-sm text-gray-600">
                    Address: <span className="font-mono text-xs bg-white px-2 py-1 rounded">{user.wallet_address}</span>
                  </p>
                  {balance !== null ? (
                    <div>
                      <p className="text-3xl font-bold text-purple-600">{balance.toFixed(2)} GOTHOS</p>
                      <p className="text-sm text-gray-500">≈ ${(balance * 0.5).toFixed(2)} USD</p>
                    </div>
                  ) : (
                    <div className="animate-pulse h-8 w-32 bg-purple-200 rounded"></div>
                  )}


                <TestLinksComponent/>
                </div>
              </div>

              {/* Buy More Section */}
              <div className="p-6 border rounded-xl">
                <h2 className="text-lg font-semibold mb-4">Buy More GOTHOS</h2>
                <div className="space-y-4">
                  <div className="flex items-center justify-center space-x-4">
                    <button
                      onClick={() => setQuantity(Math.max(1, quantity - 1))}
                      className="w-10 h-10 rounded-full bg-purple-100 hover:bg-purple-200"
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={quantity}
                      onChange={(e) => setQuantity(Math.max(1, Math.min(100, parseInt(e.target.value) || 1)))}
                      className="w-20 text-center border rounded p-2"
                    />
                    <button
                      onClick={() => setQuantity(Math.min(100, quantity + 1))}
                      className="w-10 h-10 rounded-full bg-purple-100 hover:bg-purple-200"
                    >
                      +
                    </button>
                  </div>

                  <div className="bg-gray-50 p-4 rounded-lg">
                    <p className="flex justify-between mb-2">
                      <span>Price per GOTHOS:</span>
                      <span>$0.50</span>
                    </p>
                    <p className="flex justify-between font-bold">
                      <span>Total:</span>
                      <span>${calculateTotal()} USD</span>
                    </p>
                    <p className="flex justify-between text-sm text-gray-600">
                      <span>You will receive:</span>
                      <span>{quantity * 2} GOTHOS</span>
                    </p>
                  </div>

                  {showConfirm ? (
                    <div className="space-y-4">
                      <p className="text-center">Confirm purchase of {quantity * 2} GOTHOS for ${calculateTotal()} USD?</p>
                      <div className="flex justify-center space-x-4">
                        <button
                          onClick={() => setShowConfirm(false)}
                          className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleBuyMore}
                          disabled={isLoading}
                          className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700 disabled:opacity-50"
                        >
                          {isLoading ? 'Processing...' : 'Confirm'}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => setShowConfirm(true)}
                      disabled={isLoading}
                      className="w-full py-2 bg-purple-600 text-white rounded hover:bg-purple-700 disabled:opacity-50"
                    >
                      {isLoading ? 'Processing...' : 'Buy Now'}
                    </button>
                  )}

                  {error && (
                    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
                      {error}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Password Change Section */}
            <div className="p-6 border rounded-xl">
              <h2 className="text-lg font-semibold mb-4">Change Password</h2>
              <form onSubmit={handlePasswordChange} className="space-y-4">
                <div>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New password"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-purple-500"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm new password"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-purple-500"
                  />
                </div>
                {passwordError && (
                  <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
                    {passwordError}
                  </div>
                )}
                {changeSuccess && (
                  <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4">
                    Password changed successfully!
                  </div>
                )}
                <button
                  type="submit"
                  className="w-full py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
                >
                  Update Password
                </button>
              </form>
            </div>

            <div className="mt-8 space-y-6">
  <div className="bg-white rounded-xl shadow-lg p-6">
    <h2 className="text-2xl font-bold text-gray-800 mb-4">Gotho Word Generator</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="border rounded-xl p-4">
        <h3 className="text-lg font-semibold mb-2">Simple Test</h3>
        <TestLinksComponent />
      </div>
      <div className="border rounded-xl p-4">
        <h3 className="text-lg font-semibold mb-2">Typewriter Effect</h3>
        <TypewriterComponent />
      </div>
    </div>
  </div>
</div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;