import { createContext, useContext, useState } from 'react';

const FileContext = createContext(null);

export function FileProvider({ children }) {
  const [fileLink, setFileLink] = useState('');
  return (
    <FileContext.Provider value={{ fileLink, setFileLink }}>
      {children}
    </FileContext.Provider>
  );
}

export function useFileLink() {
  const context = useContext(FileContext);
  if (!context) {
    throw new Error('useFileLink must be used within a FileProvider');
  }
  return context;
}