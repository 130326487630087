import React, { useEffect } from 'react';
import { useFileLink } from './FileContext';

const TestComponent = () => {
  const { fileLink } = useFileLink();

  // Add effect to log when fileLink changes
  useEffect(() => {
    console.log('FileLink changed:', fileLink);
  }, [fileLink]);

  return (
    <div className="p-4 bg-gray-50 rounded-lg">
      <h3>File Link Test Component</h3>
      <div className="mt-2">
        {fileLink ? (
          <>
            <p>Current Link:</p>
            <a
              href={fileLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 break-all"
            >
              {fileLink}
            </a>
          </>
        ) : (
          <p>No link available</p>
        )}
      </div>
    </div>
  );
};

export default TestComponent;