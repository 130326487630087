import { useState } from 'react'

export default function SongSearch() {
  const [results, setResults] = useState([])

  const displaySong = (song) => {
    const { attributes } = song
    return (
      <div key={song.id} className="p-4 border rounded mb-2">
        <img
          src={attributes.artwork.url.replace('{w}x{h}', '100x100')}
          alt={attributes.name}
          className="float-left mr-4 w-24 h-24"
        />
        <h3 className="font-bold">{attributes.name}</h3>
        <p>{attributes.artistName}</p>
        <p>{attributes.albumName}</p>
        {attributes.previews?.[0]?.url && (
          <audio controls src={attributes.previews[0].url} />
        )}
      </div>
    )
  }

  const handleSearch = async () => {
    const response = await fetch('http://localhost:3001/api/search?term=Bad Romance Lady Gaga')
    const data = await response.json()
    setResults(data.results.songs.data)
  }

  return (
    <div className="p-4">
      <button onClick={handleSearch} className="mb-4 px-4 py-2 bg-blue-500 text-white rounded">
        Search
      </button>
      <div>
        {results.map(displaySong)}
      </div>
    </div>
  )
}