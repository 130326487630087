import React, { useState, useRef, useEffect } from 'react';

// Helper function to calculate the distance from a point to a line segment
const distToSegment = (p, v, w) => {
  const l2 = (v.x - w.x) ** 2 + (v.y - w.y) ** 2;
  if (l2 === 0) return Math.sqrt((p.x - v.x) ** 2 + (p.y - v.y) ** 2);
  const t = Math.max(0, Math.min(1, ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2));
  const proj = { x: v.x + t * (w.x - v.x), y: v.y + t * (w.y - v.y) };
  return Math.sqrt((p.x - proj.x) ** 2 + (p.y - proj.y) ** 2);
};

const PolygonImageCropper2 = () => {
  const [points, setPoints] = useState([]);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [isCropped, setIsCropped] = useState(false);
  const [isReverseCrop, setIsReverseCrop] = useState(false);
  const [dragIndex, setDragIndex] = useState(null);
  const [imageSrc, setImageSrc] = useState(null); // Holds the current image source
  const [imageURL, setImageURL] = useState('');   // Holds the user-input image URL
  const [isLoading, setIsLoading] = useState(false); // For loading indicators
  const [error, setError] = useState(''); // For error messages

  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const fileInputRef = useRef(null);

  // Load image and set dimensions
  useEffect(() => {
    if (!imageSrc) return;

    const img = new Image();
    img.onload = () => {
      setImageSize({ width: img.width, height: img.height });
      setError('');
    };
    img.onerror = () => {
      setError('Failed to load the image. Please check the URL or upload a valid image.');
      setImageSrc(null);
    };
    img.crossOrigin = 'Anonymous'; // To handle CORS if needed
    img.src = imageSrc;
  }, [imageSrc]);

  // Handle image URL input change
  const handleURLChange = (e) => {
    setImageURL(e.target.value);
  };

  // Handle image URL submission
  const handleURLSubmit = (e) => {
    e.preventDefault();
    if (!imageURL) {
      setError('Please enter an image URL.');
      return;
    }
    setIsLoading(true);
    // Optionally, you can add more validation for URL format here
    setImageSrc(imageURL);
    setIsLoading(false);
  };

  // Handle file upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please upload a valid image file.');
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setError('');
      };
      reader.onerror = () => {
        setError('Failed to read the file. Please try again.');
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle image clicks to add points
  const handleImageClick = (e) => {
    if (!imageSrc || isCropped) return;

    const rect = imageRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    if (points.length >= 3) {
      let minDist = Infinity;
      let insertIndex = -1;

      for (let i = 0; i < points.length; i++) {
        const j = (i + 1) % points.length;
        const dist = distToSegment({ x, y }, points[i], points[j]);
        if (dist < minDist) {
          minDist = dist;
          insertIndex = j;
        }
      }

      if (minDist < 2) { // Threshold to add mid-point
        setPoints((prev) => [
          ...prev.slice(0, insertIndex),
          { x, y },
          ...prev.slice(insertIndex),
        ]);
        return;
      }
    }

    setPoints((prev) => [...prev, { x, y }]);
  };

  // Handle mouse down on a point to initiate dragging
  const handlePointMouseDown = (index) => (e) => {
    e.stopPropagation();
    setDragIndex(index);
  };

  // Handle mouse movement for dragging points
  const handleMouseMove = (e) => {
    if (dragIndex === null) return;
    if (!imageRef.current) return;

    const rect = imageRef.current.getBoundingClientRect();
    const x = Math.max(0, Math.min(100, ((e.clientX - rect.left) / rect.width) * 100));
    const y = Math.max(0, Math.min(100, ((e.clientY - rect.top) / rect.height) * 100));

    setPoints((prev) =>
      prev.map((point, i) => (i === dragIndex ? { x, y } : point))
    );
  };

  // Handle mouse up to stop dragging
  const handleMouseUp = () => {
    setDragIndex(null);
  };

  // Handle cropping action
  const handleCrop = () => {
    if (points.length < 3) {
      alert('Please create at least 3 points to form a polygon.');
      return;
    }
    setIsCropped(true);
  };

  // Reset the cropping process
  const handleReset = () => {
    setPoints([]);
    setIsCropped(false);
    setImageSrc(null);
    setImageURL('');
    setError('');
  };

  // Convert percentage points to absolute pixels
  const getPolygonPointsAbsolute = () => {
    return points
      .map(
        (p) =>
          `${(p.x / 100) * imageSize.width},${(p.y / 100) * imageSize.height}`
      )
      .join(' ');
  };

  // Retain percentage-based points for on-screen display
  const getPolygonPointsPercentage = () => {
    return points.map((p) => `${p.x}% ${p.y}%`).join(', ');
  };

  // Handle SVG download
  const handleDownload = () => {
    if (imageSize.width === 0 || imageSize.height === 0 || !imageSrc) {
      alert('Image is not loaded yet.');
      return;
    }

    const absolutePoints = getPolygonPointsAbsolute();
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="${imageSize.width}" height="${imageSize.height}" viewBox="0 0 ${imageSize.width} ${imageSize.height}">
        <defs>
          <clipPath id="clip">
            <polygon points="${absolutePoints}" />
          </clipPath>
        </defs>
        <image width="${imageSize.width}" height="${imageSize.height}" href="${imageSrc}" clip-path="url(#clip)" />
        ${
          isReverseCrop
            ? `<rect width="${imageSize.width}" height="${imageSize.height}" fill="black" clip-path="url(#clip)" />`
            : ''
        }
      </svg>
    `;

    const blob = new Blob([svg], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'cropped_image.svg';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1.5rem',
        padding: '2rem',
        maxWidth: '800px',
        margin: '0 auto',
      }}
    >
      {/* Image Source Selection */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <form onSubmit={handleURLSubmit} style={{ width: '100%', maxWidth: '500px', display: 'flex', gap: '0.5rem' }}>
          <input
            type="text"
            placeholder="Enter image URL"
            value={imageURL}
            onChange={handleURLChange}
            style={{
              flex: 1,
              padding: '0.5rem',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
          <button
            type="submit"
            style={{
              padding: '0.5rem 1rem',
              background: '#007BFF',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Load Image
          </button>
        </form>
        <span>Or</span>
        <button
          onClick={() => fileInputRef.current.click()}
          style={{
            padding: '0.5rem 1rem',
            background: '#6c757d',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Upload Image
        </button>
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </div>

      {/* Display Loading or Error Messages */}
      {isLoading && <p>Loading image...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Image and Polygon Cropping Area */}
      {imageSrc && (
        <>
          <div
            ref={containerRef}
            style={{
              position: 'relative',
              width: `${imageSize.width}px`,
              height: `${imageSize.height}px`,
              maxWidth: '100%',
              maxHeight: '80vh',
              overflow: 'hidden',
              cursor: isCropped ? 'default' : 'crosshair',
              border: '2px solid #ddd',
              borderRadius: '8px',
            }}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <img
              ref={imageRef}
              src={imageSrc}
              alt="Original"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                clipPath: isCropped
                  ? isReverseCrop
                    ? `polygon(0 0, 100% 0, 100% 100%, 0 100%)`
                    : `polygon(${getPolygonPointsPercentage()})`
                  : 'none',
                userSelect: 'none',
                pointerEvents: isCropped ? 'none' : 'auto',
              }}
              onClick={handleImageClick}
              draggable={false}
            />
            {isCropped && isReverseCrop && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  clipPath: `polygon(${getPolygonPointsPercentage()})`,
                  background: 'black',
                  pointerEvents: 'none',
                }}
              />
            )}
            {!isCropped && points.length > 0 && (
              <svg
                width="100%"
                height="100%"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  pointerEvents: 'none',
                }}
                viewBox={`0 0 100 100`}
                preserveAspectRatio="none"
              >
                <polygon
                  points={points.map((p) => `${p.x},${p.y}`).join(' ')}
                  style={{
                    fill: 'rgba(255,0,0,0.3)',
                    stroke: 'red',
                    strokeWidth: '0.5',
                  }}
                />
                {points.map((point, index) => (
                  <circle
                    key={index}
                    cx={point.x}
                    cy={point.y}
                    r="1.5"
                    fill={dragIndex === index ? 'blue' : 'red'}
                    style={{
                      cursor: 'move',
                      pointerEvents: 'auto',
                    }}
                    onMouseDown={handlePointMouseDown(index)}
                  />
                ))}
              </svg>
            )}
          </div>

          {/* Controls */}
          <div
            style={{
              width: '100%',
              maxWidth: '600px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <label>
                <input
                  type="checkbox"
                  checked={isReverseCrop}
                  onChange={(e) => setIsReverseCrop(e.target.checked)}
                  disabled={isCropped}
                />{' '}
                Reverse Crop
              </label>
            </div>

            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <button
                onClick={handleCrop}
                disabled={isCropped}
                style={{
                  padding: '0.5rem 1rem',
                  background: isCropped ? '#ccc' : '#28a745',
                  color: isCropped ? '#666' : '#fff',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: isCropped ? 'not-allowed' : 'pointer',
                }}
              >
                {isCropped ? 'Cropped' : 'Crop Image'}
              </button>
              <button
                onClick={handleReset}
                style={{
                  padding: '0.5rem 1rem',
                  background: '#dc3545',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Reset
              </button>
              {isCropped && (
                <button
                  onClick={handleDownload}
                  style={{
                    padding: '0.5rem 1rem',
                    background: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Download Cropped Image (SVG)
                </button>
              )}
            </div>
          </div>

          {/* Download Instructions */}
          {isCropped && (
            <div style={{ marginTop: '1rem', textAlign: 'center' }}>
              <p>
                Download the cropped image by clicking the "Download Cropped
                Image (SVG)" button above.
              </p>
            </div>
          )}

          {/* Instructions */}
          {!isCropped && imageSrc && (
            <p style={{ maxWidth: '600px', textAlign: 'center', marginTop: '1rem' }}>
              Click on the image to add points and form a polygon. Click on lines to add
              mid-points. Drag points to adjust the polygon. Once satisfied, click "Crop
              Image" to apply the crop.
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default PolygonImageCropper2;