// deviceController.js
import * as THREE from 'three';

export class DeviceController {
  constructor(camera) {
    this.camera = camera;
    this.enabled = false;
    this.deviceOrientation = {};
    this.screenOrientation = 0;
    this.initialRotation = new THREE.Euler();
    this.tempQuaternion = new THREE.Quaternion();

    this.handleDeviceOrientation = this.handleDeviceOrientation.bind(this);
    this.handleScreenOrientation = this.handleScreenOrientation.bind(this);
  }

  connect() {
    this.enabled = true;
    this.initialRotation.copy(this.camera.rotation);
    window.addEventListener('deviceorientation', this.handleDeviceOrientation);
    window.addEventListener('orientationchange', this.handleScreenOrientation);
    this.handleScreenOrientation();
  }

  disconnect() {
    this.enabled = false;
    window.removeEventListener('deviceorientation', this.handleDeviceOrientation);
    window.removeEventListener('orientationchange', this.handleScreenOrientation);
  }

  handleDeviceOrientation(event) {
    if (!this.enabled) return;
    this.deviceOrientation = event;
  }

  handleScreenOrientation() {
    this.screenOrientation = window.orientation || 0;
  }

  update() {
    if (!this.enabled || !this.deviceOrientation) return;

    const alpha = THREE.MathUtils.degToRad(this.deviceOrientation.alpha || 0);
    const beta = THREE.MathUtils.degToRad(this.deviceOrientation.beta || 0);
    const gamma = THREE.MathUtils.degToRad(this.deviceOrientation.gamma || 0);
    const orient = THREE.MathUtils.degToRad(this.screenOrientation);

    // ZXY rotation order
    const quaternion = this.tempQuaternion;
    quaternion.setFromEuler(new THREE.Euler(beta, alpha, -gamma, 'YXZ'));
    quaternion.multiply(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), -orient));
    quaternion.multiply(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2));

    this.camera.quaternion.copy(quaternion);
  }
}