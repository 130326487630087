import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('user');

    if (token && userData) {
      try {
        const parsedUser = JSON.parse(userData);
        setUser(parsedUser);
        verifyAuth(); // Verify token on mount
      } catch (error) {
        console.error('Error parsing stored user data:', error);
        handleLogout();
      }
    }
    setLoading(false);
  }, []);

  const login = async (userData) => {
    try {
      localStorage.setItem('token', userData.token);
      localStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
  };

const verifyAuth = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    handleLogout();
    return false;
  }

  try {
    const response = await fetch('/api/verify-token', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      if (response.status === 401) {
        handleLogout();
      }
      return false;
    }

    const data = await response.json();
    if (data.valid) {
      setUser(data.user);
      return true;
    } else {
      handleLogout();
      return false;
    }
  } catch (error) {
    console.error('Auth verification error:', error);
    handleLogout();
    return false;
  }
};

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center">Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, login, logout: handleLogout, verifyAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;