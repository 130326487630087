


import React, { useState, useEffect, useCallback, useRef } from 'react';

// Constants for note mappings
const SEMITONE_TO_NOTE = {
  0: ['C', 'B#'],
  1: ['C#', 'Db'],
  2: ['D'],
  3: ['D#', 'Eb'],
  4: ['E', 'Fb'],
  5: ['F', 'E#'],
  6: ['F#', 'Gb'],
  7: ['G'],
  8: ['G#', 'Ab'],
  9: ['A'],
  10: ['A#', 'Bb'],
  11: ['B', 'Cb']
};

// Keyboard mapping for piano
const KEY_MAP = {
  'a': 0,  // C
  'w': 1,  // C#
  's': 2,  // D
  'e': 3,  // D#
  'd': 4,  // E
  'f': 5,  // F
  't': 6,  // F#
  'g': 7,  // G
  'y': 8,  // G#
  'h': 9,  // A
  'u': 10, // A#
  'j': 11  // B
};

const ArpeggioGenerator = () => {
  // Audio Context States
  const [audioContext, setAudioContext] = useState(null);
  const [masterGainNode, setMasterGainNode] = useState(null);
  const [analyser, setAnalyser] = useState(null);

  // Buffer States
  const [melodyBuffer, setMelodyBuffer] = useState(null);
  const [accompanimentBuffer, setAccompanimentBuffer] = useState(null);
  const [previewBuffer, setPreviewBuffer] = useState(null);

  // Note Sequence States
  const [melodyNotes, setMelodyNotes] = useState([0, 4, 7]);
  const [accompanimentNotes, setAccompanimentNotes] = useState([0, 4, 7]);
  const [activeNotes, setActiveNotes] = useState([]);

  // Playback Control States
  const [isPlaying, setIsPlaying] = useState(false);
  const [delay, setDelay] = useState(250);
  const [speed, setSpeed] = useState(1.0);
  const [volume, setVolume] = useState(0.5);
  const [repetitions, setRepetitions] = useState(1);
  const [playbackMode, setPlaybackMode] = useState('simultaneous');

  // MIDI States
  const [midiAccess, setMidiAccess] = useState(null);
  const [midiInputs, setMidiInputs] = useState([]);
  const [selectedMidiInput, setSelectedMidiInput] = useState('');

  // UI States
  const [errorMessage, setErrorMessage] = useState('');
  const [pianoMode, setPianoMode] = useState('melody');

  // Refs for tracking active sources and timeouts
  const activeSourcesRef = useRef({});
  const activeTimeoutsRef = useRef([]);
  const stopPlaybackTimeoutRef = useRef(null);
  const previewSourceRef = useRef(null);

  // Initialize Audio Context and MIDI
  useEffect(() => {
    const initAudio = async () => {
      try {
        const context = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 48000,
          latencyHint: 'interactive',
        });

        const masterGain = context.createGain();
        const analyserNode = context.createAnalyser();

        masterGain.connect(analyserNode);
        analyserNode.connect(context.destination);
        masterGain.gain.setValueAtTime(volume, context.currentTime);

        analyserNode.fftSize = 2048;

        setAudioContext(context);
        setMasterGainNode(masterGain);
        setAnalyser(analyserNode);

        // Request MIDI access
        if (navigator.requestMIDIAccess) {
          const midiAccessObj = await navigator.requestMIDIAccess();
          handleMIDISuccess(midiAccessObj);
        }
      } catch (error) {
        console.error('Audio initialization error:', error);
        setErrorMessage('Failed to initialize audio system.');
      }
    };

    initAudio();

    return () => {
      stopAllAudio();
      if (audioContext) {
        audioContext.close();
      }
    };
  }, []);

  // MIDI handling
  const handleMIDISuccess = (midiAccessObj) => {
    setMidiAccess(midiAccessObj);
    const inputs = Array.from(midiAccessObj.inputs.values());
    setMidiInputs(inputs);
    if (inputs.length > 0) {
      setSelectedMidiInput(inputs[0].id);
    }
  };

  // Handle MIDI input selection
  useEffect(() => {
    if (midiAccess && selectedMidiInput) {
      const input = midiAccess.inputs.get(selectedMidiInput);
      if (input) {
        input.onmidimessage = handleMIDIMessage;
      }
    }
  }, [midiAccess, selectedMidiInput]);

  // Handle MIDI messages
  const handleMIDIMessage = async (message) => {
    const [command, note, velocity] = message.data;

    if (audioContext?.state === 'suspended') {
      await audioContext.resume();
    }

    const semitone = note % 12;

    if (command === 144 && velocity > 0) { // Note On
      playPreviewNote(semitone);
      if (pianoMode === 'melody') {
        setMelodyNotes(prev => [...prev, semitone]);
      } else {
        setAccompanimentNotes(prev => [...prev, semitone]);
      }
    }
  };

  // Audio file loading
  const loadAudioFile = useCallback(async (file, setBuffer) => {
    if (!audioContext || !file) return;

    try {
      const arrayBuffer = await file.arrayBuffer();
      const decodedBuffer = await audioContext.decodeAudioData(arrayBuffer);
      setBuffer(decodedBuffer);

      if (!previewBuffer) {
        setPreviewBuffer(decodedBuffer);
      }

      setErrorMessage('');
    } catch (error) {
      console.error('Error loading audio file:', error);
      setErrorMessage('Error loading audio file. Please try another file.');
    }
  }, [audioContext, previewBuffer]);

  // Note creation and playback
  const createNote = useCallback((buffer, semitone, startTime, duration, velocity = 1) => {
    if (!audioContext || !buffer || semitone === null || !masterGainNode) return null;

    const source = audioContext.createBufferSource();
    const gainNode = audioContext.createGain();

    source.buffer = buffer;
    source.playbackRate.value = Math.pow(2, semitone / 12) * speed;

    gainNode.gain.setValueAtTime(0, startTime);
    gainNode.gain.linearRampToValueAtTime(velocity * volume, startTime + 0.005);
    gainNode.gain.setValueAtTime(velocity * volume, startTime + duration - 0.005);
    gainNode.gain.linearRampToValueAtTime(0, startTime + duration);

    source.connect(gainNode);
    gainNode.connect(masterGainNode);

    const sourceId = Date.now() + Math.random();
    activeSourcesRef.current[sourceId] = { source, gainNode, semitone };

    source.onended = () => {
      delete activeSourcesRef.current[sourceId];
      setActiveNotes(prev => prev.filter(note => note !== semitone));
      source.disconnect();
      gainNode.disconnect();
    };

    source.start(startTime);
    source.stop(startTime + duration);
    setActiveNotes(prev => [...prev, semitone]);

    return sourceId;
  }, [audioContext, masterGainNode, speed, volume]);

  // Preview note playback
  const playPreviewNote = useCallback((semitone) => {
    if (!audioContext || !previewBuffer) return;

    if (previewSourceRef.current) {
      previewSourceRef.current.stop();
      previewSourceRef.current = null;
    }

    const startTime = audioContext.currentTime;
    createNote(previewBuffer, semitone, startTime, 0.3, 0.8);
  }, [audioContext, previewBuffer, createNote]);

  // ... (previous code remains the same)

  // Sequence playback logic
  const playSequence = useCallback((notes, buffer, startTime) => {
    notes.forEach((note, index) => {
      if (note !== null) {
        const noteStartTime = startTime + index * (delay / 1000);
        const timeout = setTimeout(() => {
          createNote(buffer, note, audioContext.currentTime, delay / 1000);
        }, noteStartTime * 1000);
        activeTimeoutsRef.current.push(timeout);
      }
    });
  }, [delay, createNote, audioContext]);

  // Main playback control
  const playArpeggios = useCallback(() => {
    if (isPlaying || !melodyBuffer || !accompanimentBuffer) return;

    const startPlayback = async () => {
      if (audioContext.state === 'suspended') {
        await audioContext.resume();
      }

      setIsPlaying(true);
      const startTime = audioContext.currentTime;

      for (let i = 0; i < repetitions; i++) {
        if (playbackMode === 'simultaneous') {
          playSequence(melodyNotes, melodyBuffer, startTime + i * (melodyNotes.length * delay / 1000));
          playSequence(accompanimentNotes, accompanimentBuffer, startTime + i * (accompanimentNotes.length * delay / 1000));
        } else {
          const melodyDuration = melodyNotes.length * delay / 1000;
          playSequence(
            melodyNotes,
            melodyBuffer,
            startTime + i * (melodyDuration + accompanimentNotes.length * delay / 1000)
          );
          playSequence(
            accompanimentNotes,
            accompanimentBuffer,
            startTime + i * (melodyDuration + accompanimentNotes.length * delay / 1000) + melodyDuration
          );
        }
      }

      const totalDuration = playbackMode === 'simultaneous'
        ? repetitions * Math.max(melodyNotes.length, accompanimentNotes.length) * delay
        : repetitions * (melodyNotes.length + accompanimentNotes.length) * delay;

      stopPlaybackTimeoutRef.current = setTimeout(() => {
        setIsPlaying(false);
      }, totalDuration);
    };

    startPlayback();
  }, [
    isPlaying,
    melodyBuffer,
    accompanimentBuffer,
    melodyNotes,
    accompanimentNotes,
    delay,
    repetitions,
    playbackMode,
    playSequence,
    audioContext
  ]);

  // Stop all audio
  const stopAllAudio = useCallback(() => {
    // Clear all timeouts
    activeTimeoutsRef.current.forEach(clearTimeout);
    activeTimeoutsRef.current = [];

    if (stopPlaybackTimeoutRef.current) {
      clearTimeout(stopPlaybackTimeoutRef.current);
    }

    // Stop all active sources with quick fadeout
    const fadeOutTime = 0.02;
    const currentTime = audioContext?.currentTime || 0;

    Object.values(activeSourcesRef.current).forEach(({ source, gainNode }) => {
      gainNode.gain.cancelScheduledValues(currentTime);
      gainNode.gain.setValueAtTime(gainNode.gain.value, currentTime);
      gainNode.gain.linearRampToValueAtTime(0, currentTime + fadeOutTime);

      setTimeout(() => {
        source.stop();
        source.disconnect();
        gainNode.disconnect();
      }, fadeOutTime * 1000);
    });

    activeSourcesRef.current = {};
    setActiveNotes([]);
    setIsPlaying(false);
  }, [audioContext]);

  // Note management functions
  const addNote = (type, note) => {
    if (type === 'melody') {
      setMelodyNotes(prev => [...prev, note]);
    } else {
      setAccompanimentNotes(prev => [...prev, note]);
    }
  };

  const removeNote = (type, index) => {
    if (type === 'melody') {
      setMelodyNotes(prev => prev.filter((_, i) => i !== index));
    } else {
      setAccompanimentNotes(prev => prev.filter((_, i) => i !== index));
    }
  };

  // Keyboard event handling
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.repeat) return;
      const semitone = KEY_MAP[event.key.toLowerCase()];
      if (semitone !== undefined) {
        playPreviewNote(semitone);
        addNote(pianoMode, semitone);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [pianoMode, playPreviewNote]);

  // Component render
  return (
    <div className="arpeggio-generator">
      <h1>Arpeggio Generator</h1>

      {/* File Upload Section */}
      <div className="input-row">
        <label>
          Melody Audio File (WAV/MP3)
          <input
            type="file"
            accept=".wav,.mp3"
            onChange={(e) => loadAudioFile(e.target.files[0], setMelodyBuffer)}
          />
          <span className="file-name">test.mp3</span>
          <button className="choose-file">Choose File</button>
        </label>
      </div>

      <div className="input-row">
        <label>
          Accompaniment Audio File (WAV/MP3)
          <input
            type="file"
            accept=".wav,.mp3"
            onChange={(e) => loadAudioFile(e.target.files[0], setAccompanimentBuffer)}
          />
          <span className="file-name">test.mp3</span>
          <button className="choose-file">Choose File</button>
        </label>
      </div>

      {/* Controls */}
      <div className="control-row">
        <label>
          Delay: {delay}ms
          <input
            type="range"
            min="50"
            max="500"
            value={delay}
            onChange={(e) => setDelay(Number(e.target.value))}
          />
        </label>
      </div>

      <div className="control-row">
        <label>
          Speed: {speed.toFixed(2)}x
          <input
            type="range"
            min="0.5"
            max="2"
            step="0.1"
            value={speed}
            onChange={(e) => setSpeed(Number(e.target.value))}
          />
        </label>
      </div>

      <div className="control-row">
        <label>
          Volume: {Math.round(volume * 100)}%
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={(e) => setVolume(Number(e.target.value))}
          />
        </label>
      </div>

      <div className="control-row">
        <label>
          Repetitions: {repetitions}
          <input
            type="range"
            min="1"
            max="10"
            value={repetitions}
            onChange={(e) => setRepetitions(Number(e.target.value))}
          />
        </label>
      </div>

      <div className="control-row">
        <label>
          Playback Mode
          <select
            value={playbackMode}
            onChange={(e) => setPlaybackMode(e.target.value)}
          >
            <option value="simultaneous">Simultaneous</option>
            <option value="sequential">Sequential</option>
          </select>
        </label>
      </div>

      {/* Sequences */}
      <div className="sequence-section">
        <h2>Melody Sequence</h2>
        <div className="note-list">
          {melodyNotes.map((note, index) => (
            <div key={index} className="note-item">
              <span>{note !== null ? SEMITONE_TO_NOTE[note][0] : 'Rest'}</span>
              <button
                onClick={() => removeNote('melody', index)}
                className="remove-note"
              >
                ×
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="sequence-section">
        <h2>Accompaniment Sequence</h2>
        <div className="note-list">
          {accompanimentNotes.map((note, index) => (
            <div key={index} className="note-item">
              <span>{note !== null ? SEMITONE_TO_NOTE[note][0] : 'Rest'}</span>
              <button
                onClick={() => removeNote('accompaniment', index)}
                className="remove-note"
              >
                ×
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Mode Selection */}
      <div className="mode-selection">
        <label>
          <input
            type="radio"
            name="mode"
            value="melody"
            checked={pianoMode === 'melody'}
            onChange={(e) => setPianoMode(e.target.value)}
          />
          Melody
        </label>
        <label>
          <input
            type="radio"
            name="mode"
            value="accompaniment"
            checked={pianoMode === 'accompaniment'}
            onChange={(e) => setPianoMode(e.target.value)}
          />
          Accompaniment
        </label>
      </div>

      {/* Piano Keys */}
      <div className="piano">
        {[
          { note: 'C', shortcut: 'a' },
          { note: 'C#', shortcut: 'w' },
          { note: 'D', shortcut: 's' },
          { note: 'D#', shortcut: 'e' },
          { note: 'E', shortcut: 'd' },
          { note: 'F', shortcut: 'f' },
          { note: 'F#', shortcut: 't' },
          { note: 'G', shortcut: 'g' },
          { note: 'G#', shortcut: 'y' },
          { note: 'A', shortcut: 'h' },
          { note: 'A#', shortcut: 'u' },
          { note: 'B', shortcut: 'j' }
        ].map((key, index) => (
          <div key={index} className="note-key">
            {key.note}
            <span className="shortcut">{key.shortcut}</span>
          </div>
        ))}
      </div>

      <style jsx>{`
        .arpeggio-generator {
          padding: 20px;
          font-family: Arial, sans-serif;
        }

        h1 {
          margin-bottom: 20px;
        }

        .input-row {
          margin-bottom: 10px;
        }

        .control-row {
          margin-bottom: 15px;
        }

        .control-row label {
          display: block;
        }

        input[type="range"] {
          width: 200px;
          margin: 0 10px;
        }

        .sequence-section {
          margin: 20px 0;
        }

        .note-list {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
        }

        .note-item {
          display: flex;
          align-items: center;
          background: #f0f0f0;
          padding: 5px 10px;
          border-radius: 3px;
        }

        .remove-note {
          margin-left: 5px;
          border: none;
          background: none;
          color: #666;
          cursor: pointer;
          font-size: 16px;
        }

        .remove-note:hover {
          color: #ff0000;
        }

        .mode-selection {
          margin: 20px 0;
        }

        .mode-selection label {
          margin-right: 20px;
        }

        .piano {
          display: flex;
          flex-direction: column;
          gap: 5px;
          margin-top: 20px;
        }

        .note-key {
          padding: 5px 10px;
          background: #f0f0f0;
          border: 1px solid #ddd;
          border-radius: 3px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .shortcut {
          color: #666;
          font-size: 0.8em;
        }

        .choose-file {
          margin-left: 10px;
          padding: 2px 8px;
          background: #f0f0f0;
          border: 1px solid #ddd;
          border-radius: 3px;
          cursor: pointer;
        }

        .file-name {
          margin-left: 10px;
          color: #666;
        }
      `}</style>
    </div>
  );
};

export default ArpeggioGenerator;