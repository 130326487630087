import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthContext";
import SpaceVisualization from "./future";
import TypewriterComponent from "./WordGenerator";
import TestComponent from "./TestComponent";
import TestAPI from "./TestAPI";
import PolygonImageCropper2 from "./BasicImageDisplay";
import ChordPlayer from "./ChordPlayer";
import PanoramaViewer from "./PanoramaViewer";
import VanguardClone from "./VangaurdClone";
import PitchArpeggioVisualizer from "./generateArpeggio";
import ArpeggioGenerator from "./Appregio2";
import SongSearch from "./SongSearch";

import FileUploader from "./FileUploader"
import {FileProvider} from "./FileContext";
import TestLinksComponent from "./TestLinksComponent";
import AudioSeperator from "./AudioSeperator";
import VideoTimelineEditor from "./VideoTimelineEditor";
import KnightScene from "./KnightScene";
import Combo from "./Combo";
import AudioMaster from "./AudioMaster";
import ChordGenerator from "./ChordGnerator";

const LandingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showResetForm, setShowResetForm] = useState(false);
  const [resetIdentifier, setResetIdentifier] = useState('');
  const [resetMessage, setResetMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error('Invalid credentials');
      }

      const data = await response.json();
      await login(data);
      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
    }
  };


 const handleResetPassword = async (e) => {
    e.preventDefault();
    setError(null);
    setResetMessage('');

    try {
      const response = await fetch('/api/request_password_reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: resetIdentifier }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to reset password');
      }

      setResetMessage('Password has been reset. Please check your email for the temporary password.');
      // For development only - remove in production
      if (data.temp_password) {
        setResetMessage(`Temporary password: ${data.temp_password}`);
      }

      // Clear form
      setResetIdentifier('');

    } catch (error) {
      setError(error.message);
    }
  };
  const handleBuyGothos = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('/api/init-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to initialize checkout');
      }

      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (error) {
      console.error('Checkout error:', error);
      setError(error.message || 'Failed to start checkout process');
    } finally {
      setIsLoading(false);
    }
     const handleResetPassword = async (e) => {
    e.preventDefault();
    setError(null);
    setResetMessage('');

    try {
      const response = await fetch('/api/request_password_reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: resetIdentifier }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to reset password');
      }

      setResetMessage('Password has been reset. Please check your email for the temporary password.');
      // For development only:
      if (data.temp_password) {
        setResetMessage(`Temporary password: ${data.temp_password}`);
      }

      // Clear form
      setResetIdentifier('');

    } catch (error) {
      setError(error.message);
    }
  };

  };
return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-gray-100">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-4xl font-bold mb-6 text-gray-900 text-center">Welcome to Gothos</h1>

        {!showResetForm ? (
          // Regular Login Form
          <div className="mb-8">
            <form onSubmit={handleLogin} className="space-y-4">
              <div>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-6 py-2 rounded-lg font-medium transition-colors duration-200 hover:bg-blue-700"
              >
                Login
              </button>
            </form>

            <button
              onClick={() => setShowResetForm(true)}
              className="w-full text-gray-600 underline mt-4 hover:text-gray-800"
            >
              Forgot Password?
            </button>
          </div>
        ) : (
          // Password Reset Form
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-900">Reset Password</h2>
            <form onSubmit={handleResetPassword} className="space-y-4">
              <div>
                <input
                  type="text"
                  value={resetIdentifier}
                  onChange={(e) => setResetIdentifier(e.target.value)}
                  placeholder="Username or Email"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>

              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-6 py-2 rounded-lg font-medium transition-colors duration-200 hover:bg-blue-700"
              >
                Reset Password
              </button>

              <button
                type="button"
                onClick={() => setShowResetForm(false)}
                className="w-full text-gray-600 underline hover:text-gray-800"
              >
                Back to Login
              </button>
            </form>
          </div>
        )}

        {resetMessage && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6">
            {resetMessage}
          </div>
        )}

        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6">
            {error}
          </div>
        )}

        <div className="border-t border-gray-200 my-8"></div>

<TestAPI/>
        <PolygonImageCropper2/>
         <ChordPlayer/>
          <PanoramaViewer/>
      < ArpeggioGenerator />
<PitchArpeggioVisualizer/>
        <SongSearch/>
            <FileProvider>
    <FileUploader/>
      <TestComponent />
    </FileProvider>
          <AudioMaster/>
          <AudioSeperator/>
          <VideoTimelineEditor/>
          <ChordGenerator/>
          {/*<Combo/>*/}
         <KnightScene modelUrl="https://eu2.contabostorage.com/7592bce2b1b24bce930b49e372e07e0c:xen/6e7c6510aede41b4ac70f987bbd19ea8_standtositground-m2l-396574.fbx" />
        <SpaceVisualization/>



          <div className="text-gray-600 mb-8 prose">
          <h2 className="text-xl font-bold mb-4">What is Gothos?</h2>
          <p>An experimental platform for audio manipulation and creative expression. Currently in beta testing.</p>

          <h3 className="text-lg font-bold mt-4 mb-2">Early Access Features:</h3>
          <ul className="list-disc pl-5 mb-4">
            <li>Free testing period</li>
            <li>Explore unique audio tools</li>
            <li>Provide valuable feedback</li>
            <li>Help build the community</li>
          </ul>

          <p className="italic text-sm">Note: This is a research project in active development.</p>
        </div>

        <button
          onClick={handleBuyGothos}
          disabled={isLoading}
          className={`
            w-full bg-purple-600 text-white px-6 py-3 rounded-lg
            font-medium transition-colors duration-200
            ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700'}
          `}
        >
          {isLoading ? 'Processing...' : 'Join Beta'}
        </button>
      </div>
    </div>
  );
};

export default LandingPage;