import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';

// Scene Configuration
const GRID_SIZE = 1000;
const GRID_DIVISIONS = 20;
const SNAP_INCREMENT = 10;



const ENVIRONMENT_PRESETS = [
  {
    name: "Space",
    type: "color",
    value: 0x000000
  },
  {
    name: "Blue Sky",
    type: "color",
    value: 0x87CEEB
  },
  {
    name: "Studio Black",
    type: "color",
    value: 0x000000
  },
  {
    name: "Green Screen",
    type: "color",
    value: 0x00FF00
  }
];

const SCENE_CONFIG = {
  showStats: true,
  showGrid: true,
  showAxes: true,
  snapToGrid: false,
  gridSize: GRID_SIZE,
  gridDivisions: GRID_DIVISIONS,
  snapIncrement: SNAP_INCREMENT
};

// Character Definitions
const CHARACTERS = [
  {
    id: 'character1',
    name: "Default Character",
    modelUrl: "https://eu2.contabostorage.com/7592bce2b1b24bce930b49e372e07e0c:xen/autorig_actor.fbx",
    position: { x: 0, y: 0, z: 0 },
    rotation: { y: 0 },
    animations: [
      {
        name: "Stand To Sit",
        url: "https://eu2.contabostorage.com/7592bce2b1b24bce930b49e372e07e0c:xen/6e7c6510aede41b4ac70f987bbd19ea8_standtositground-m2l-396574.fbx"
      }
    ]
  }
];

const KnightScene = () => {
  // Scene State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sceneConfig, setSceneConfig] = useState(SCENE_CONFIG);

  // Character State
  const [loadedCharacters, setLoadedCharacters] = useState(new Map());
  const [selectedCharacterId, setSelectedCharacterId] = useState(null);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0, z: 0 });
  const [rotation, setRotation] = useState({ y: 0 });

  // Animation State
  const [animations, setAnimations] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loadingAnimations, setLoadingAnimations] = useState(false);
const [currentEnvironment, setCurrentEnvironment] = useState(ENVIRONMENT_PRESETS[0]);
const [customEnvironment, setCustomEnvironment] = useState(null);
  // Bone Controls State
  const [showBones, setShowBones] = useState(true);
  const [bones, setBones] = useState([]);
  const [selectedBone, setSelectedBone] = useState(null);
  const [manipulationMode, setManipulationMode] = useState('translate');

  // Material State
  const [materials, setMaterials] = useState([]);

  // Refs
  const containerRef = useRef(null);
  const sceneRef = useRef(null);
  const modelsRef = useRef(new Map());
  const mixersRef = useRef(new Map());
  const clockRef = useRef(new THREE.Clock());
  const transformControlsRef = useRef(null);
  const orbitControlsRef = useRef(null);
  const skeletonHelpersRef = useRef(new Map());
  const gridHelperRef = useRef(null);
  const axesHelperRef = useRef(null);
  const currentAnimationRef = useRef(null);
const environmentRef = useRef(null);
// Scene Setup Effect
  useEffect(() => {
    let renderer, camera, scene, controls;

  const init = () => {
  scene = new THREE.Scene();
  sceneRef.current = scene;
  setupEnvironment('color', currentEnvironment.value);
      // Camera setup
      camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 2000);
      camera.position.set(0, 200, 500);
      camera.lookAt(0, 100, 0);

      // Renderer setup
      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      containerRef.current.appendChild(renderer.domElement);

      setupLights(scene);
      setupGround(scene);
      setupGridAndAxes(scene);
      setupControls(camera, renderer, scene);

      // Load initial characters
      CHARACTERS.forEach(char => loadCharacter(char));

      // Start animation loop
      animate();
    };

    const setupLights = (scene) => {
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(100, 200, 100);
      directionalLight.castShadow = true;
      directionalLight.shadow.mapSize.width = 2048;
      directionalLight.shadow.mapSize.height = 2048;
      directionalLight.shadow.camera.near = 0.5;
      directionalLight.shadow.camera.far = 1000;
      directionalLight.shadow.camera.left = -500;
      directionalLight.shadow.camera.right = 500;
      directionalLight.shadow.camera.top = 500;
      directionalLight.shadow.camera.bottom = -500;
      scene.add(directionalLight);
    };

    const setupGround = (scene) => {
      const ground = new THREE.Mesh(
        new THREE.PlaneGeometry(GRID_SIZE, GRID_SIZE),
        new THREE.MeshStandardMaterial({
          color: 0x808080,
          roughness: 0.8,
          metalness: 0.2
        })
      );
      ground.rotation.x = -Math.PI / 2;
      ground.receiveShadow = true;
      ground.position.y = 0;
      scene.add(ground);
    };

    const setupGridAndAxes = (scene) => {
      // Grid Helper
      const gridHelper = new THREE.GridHelper(
        sceneConfig.gridSize,
        sceneConfig.gridDivisions,
        0x444444, // Main grid lines
        0x888888  // Secondary grid lines
      );
      gridHelper.position.y = 0.1;
      gridHelper.visible = sceneConfig.showGrid;
      scene.add(gridHelper);
      gridHelperRef.current = gridHelper;

      // Axes Helper
      const axesHelper = new THREE.AxesHelper(GRID_SIZE / 2);
      axesHelper.visible = sceneConfig.showAxes;
      scene.add(axesHelper);
      axesHelperRef.current = axesHelper;

      // Add coordinate labels
      addCoordinateLabels(scene);
    };

    const addCoordinateLabels = (scene) => {
      const createLabel = (text, position, color = 'white') => {
        const canvas = document.createElement('canvas');
        canvas.width = 256;
        canvas.height = 256;
        const context = canvas.getContext('2d');
        context.font = 'bold 48px Arial';
        context.fillStyle = color;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(text, 128, 128);

        const texture = new THREE.CanvasTexture(canvas);
        const material = new THREE.SpriteMaterial({ map: texture });
        const sprite = new THREE.Sprite(material);
        sprite.position.copy(position);
        sprite.scale.set(50, 50, 1);
        return sprite;
      };

      const labels = [
        createLabel('X+', new THREE.Vector3(100, 5, 0), '#ff4444'),
        createLabel('Z+', new THREE.Vector3(0, 5, 100), '#4444ff'),
        createLabel('(0,0)', new THREE.Vector3(0, 5, 0), '#ffffff')
      ];

      labels.forEach(label => scene.add(label));
    };

    const setupControls = (camera, renderer, scene) => {
      // Orbit Controls
      const orbitControls = new OrbitControls(camera, renderer.domElement);
      orbitControls.target.set(0, 100, 0);
      orbitControls.enableDamping = true;
      orbitControls.dampingFactor = 0.05;
      orbitControls.screenSpacePanning = false;
      orbitControls.minDistance = 100;
      orbitControls.maxDistance = 1000;
      orbitControls.maxPolarAngle = Math.PI / 2;
      orbitControlsRef.current = orbitControls;

      // Transform Controls
      const transformControls = new TransformControls(camera, renderer.domElement);
      transformControls.size = 0.75;
      transformControls.addEventListener('dragging-changed', (event) => {
        orbitControls.enabled = !event.value;
      });
      scene.add(transformControls);
      transformControlsRef.current = transformControls;
    };

    const animate = () => {
      requestAnimationFrame(animate);
      const delta = clockRef.current.getDelta();

      // Update mixers
      mixersRef.current.forEach(mixer => mixer.update(delta));

      // Update controls
      if (orbitControlsRef.current) {
        orbitControlsRef.current.update();
      }

      renderer.render(scene, camera);
    };

    // Window resize handler
    const handleResize = () => {
      if (camera && renderer) {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      }
    };

    init();
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      if (containerRef.current && renderer) {
        containerRef.current.removeChild(renderer.domElement);
      }
    };
  }, []);

// Visibility Effects
  useEffect(() => {
    if (gridHelperRef.current) {
      gridHelperRef.current.visible = sceneConfig.showGrid;
    }
    if (axesHelperRef.current) {
      axesHelperRef.current.visible = sceneConfig.showAxes;
    }
    skeletonHelpersRef.current.forEach(helper => {
      if (helper) {
        helper.visible = showBones;
      }
    });
  }, [sceneConfig.showGrid, sceneConfig.showAxes, showBones, currentEnvironment]);

  // Character Loading and Management
  const loadCharacter = async (character) => {
  setLoading(true);
  try {
    const loader = new FBXLoader();
    const fbx = await new Promise((resolve, reject) => {
      loader.load(character.modelUrl, resolve, undefined, reject);
    });

    console.log(`Loading character: ${character.name}`);
    console.log('Embedded animations:', fbx.animations); // Debug log

    // Setup character
    fbx.scale.set(1, 1, 1);
    fbx.position.set(
      character.position.x,
      character.position.y,
      character.position.z
    );
    fbx.rotation.y = THREE.MathUtils.degToRad(character.rotation.y);

    // Process meshes and bones
    const foundMaterials = new Set();
    const foundBones = [];

    fbx.traverse((object) => {
      if (object.isMesh) {
        object.castShadow = true;
        object.receiveShadow = true;
        if (object.material) {
          foundMaterials.add(object.material);
        }
      }
      if (object.isBone) {
        foundBones.push(object);
      }
    });

    // Setup skeleton helper
    if (foundBones.length > 0) {
      const helper = new THREE.SkeletonHelper(fbx);
      helper.material.linewidth = 2;
      helper.visible = showBones;
      sceneRef.current.add(helper);
      skeletonHelpersRef.current.set(character.id, helper);
    }

    // Setup animation mixer
    const mixer = new THREE.AnimationMixer(fbx);
    mixersRef.current.set(character.id, mixer);

    // Add embedded animations first
    if (fbx.animations && fbx.animations.length > 0) {
      fbx.animations.forEach(anim => {
        anim.name = anim.name || `Animation_${animations.length}`;
        setAnimations(prev => [...prev, anim]);
      });
    }

    // Store character data
    modelsRef.current.set(character.id, fbx);
    setLoadedCharacters(prev => new Map(prev).set(character.id, character));
    setBones(foundBones);
    setMaterials(Array.from(foundMaterials));

    // Add to scene
    sceneRef.current.add(fbx);

    // Load external animations
    await loadCharacterAnimations(character, mixer);

    if (!selectedCharacterId) {
      setSelectedCharacterId(character.id);
      setCoordinates(character.position);
      setRotation(character.rotation);
    }

  } catch (error) {
    console.error(`Error loading character ${character.name}:`, error);
    setError(`Failed to load character: ${character.name}`);
  } finally {
    setLoading(false);
  }
};

  // Animation Management
  const loadCharacterAnimations = async (character, mixer) => {
    setLoadingAnimations(true);
    try {
      for (const animFile of character.animations) {
        const loader = new FBXLoader();
        const animFbx = await new Promise((resolve, reject) => {
          loader.load(animFile.url, resolve, undefined, reject);
        });

        if (animFbx.animations.length > 0) {
          const anim = animFbx.animations[0];
          anim.name = animFile.name;
          setAnimations(prev => [...prev, anim]);
        }
      }
    } catch (error) {
      console.error('Error loading animations:', error);
    } finally {
      setLoadingAnimations(false);
    }
  };

  const playAnimation = (clip) => {
  if (!mixersRef.current.get(selectedCharacterId)) return;

  console.log('Playing animation:', clip.name); // Debug log

  if (currentAnimationRef.current) {
    currentAnimationRef.current.fadeOut(0.5);
  }

  const mixer = mixersRef.current.get(selectedCharacterId);
  const action = mixer.clipAction(clip);

  // Reset and configure the action
  action.reset()
       .setEffectiveTimeScale(1)
       .setEffectiveWeight(1)
       .fadeIn(0.5);

  // Ensure looping is set correctly
  action.clampWhenFinished = false;
  action.loop = THREE.LoopRepeat;

  action.play();
  currentAnimationRef.current = action;
  setIsPlaying(true);
};

  const stopAnimation = () => {
    if (currentAnimationRef.current) {
      currentAnimationRef.current.fadeOut(0.5);
      currentAnimationRef.current = null;
      setIsPlaying(false);
    }
  };

  // Movement and Positioning
  const snapToGrid = (value) => {
    if (!sceneConfig.snapToGrid) return value;
    return Math.round(value / sceneConfig.snapIncrement) * sceneConfig.snapIncrement;
  };

  const moveCharacter = (characterId, newPosition, newRotation = null) => {
    const model = modelsRef.current.get(characterId);
    if (!model) return;

    const snappedPosition = {
      x: snapToGrid(newPosition.x),
      y: snapToGrid(newPosition.y),
      z: snapToGrid(newPosition.z)
    };

    // Update position
    model.position.set(snappedPosition.x, snappedPosition.y, snappedPosition.z);

    // Update rotation if provided
    if (newRotation !== null) {
      model.rotation.y = THREE.MathUtils.degToRad(newRotation);
    }

    // Update state
    setCoordinates(snappedPosition);
    if (newRotation !== null) {
      setRotation({ y: newRotation });
    }
  };

// Bone Manipulation
  const selectBone = (boneName) => {
    setSelectedBone(boneName);
    const bone = bones.find(b => b.name === boneName);
    if (bone) {
      transformControlsRef.current.attach(bone);
      transformControlsRef.current.setMode(manipulationMode);
    } else {
      transformControlsRef.current.detach();
    }
  };

  const updateManipulationMode = (mode) => {
    setManipulationMode(mode);
    if (transformControlsRef.current && selectedBone) {
      transformControlsRef.current.setMode(mode);
    }
  };

  // Material Updates
  const updateMaterialColor = (material, color) => {
    material.color.set(color);
  };

  // Scene Configuration Updates
  const updateSceneConfig = (updates) => {
    setSceneConfig(prev => ({
      ...prev,
      ...updates
    }));
  };
// Add these to your utility functions section (where moveCharacter and other functions are)
const setupEnvironment = (type, source) => {
  if (!sceneRef.current) return;

  if (environmentRef.current) {
    sceneRef.current.remove(environmentRef.current);
  }

  let environment;
  switch (type) {
    case 'color':
      sceneRef.current.background = new THREE.Color(source);
      break;
    case 'image':
      environment = createEnvironmentSphere(source, 'image');
      sceneRef.current.add(environment);
      environmentRef.current = environment;
      break;
    case 'video':
      environment = createEnvironmentSphere(source, 'video');
      sceneRef.current.add(environment);
      environmentRef.current = environment;
      break;
  }
};

const createEnvironmentSphere = (source, type) => {
  const geometry = new THREE.SphereGeometry(500, 60, 40);
  geometry.scale(-1, 1, 1);

  let material;
  if (type === 'image') {
    const texture = new THREE.TextureLoader().load(source);
    material = new THREE.MeshBasicMaterial({ map: texture });
  } else if (type === 'video') {
    const video = document.createElement('video');
    video.src = source;
    video.loop = true;
    video.muted = true;
    const texture = new THREE.VideoTexture(video);
    material = new THREE.MeshBasicMaterial({ map: texture });
    video.play();
  }

  return new THREE.Mesh(geometry, material);
};
  // Render UI
  return (
    <div className="relative w-full h-screen">
      {/* Main Canvas */}
      <div ref={containerRef} className="w-full h-full" />

      {/* Loading Overlay */}
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-white text-xl">Loading...</div>
        </div>
      )}

      {/* Error Display */}
      {error && (
        <div className="absolute top-4 left-4 bg-red-500 text-white px-4 py-2 rounded">
          {error}
        </div>
      )}


<div className="mb-4">
  <h3 className="font-bold mb-2">Environment</h3>
  <select
    className="w-full p-2 border rounded mb-2"
    value={currentEnvironment.name}
    onChange={(e) => {
      const selected = ENVIRONMENT_PRESETS.find(env => env.name === e.target.value);
      if (selected) {
        setCurrentEnvironment(selected);
        setupEnvironment(selected.type, selected.value);
      }
    }}
  >
    {ENVIRONMENT_PRESETS.map(env => (
      <option key={env.name} value={env.name}>
        {env.name}
      </option>
    ))}
  </select>

  <div className="mt-2">
    <input
      type="file"
      accept="image/*,video/*"
      onChange={(e) => {
        const file = e.target.files?.[0];
        if (file) {
          const url = URL.createObjectURL(file);
          const type = file.type.startsWith('image/') ? 'image' : 'video';
          setCustomEnvironment({ type, url });
          setupEnvironment(type, url);
        }
      }}
      className="w-full"
    />
  </div>
</div>
      <div className="absolute top-4 right-4 bg-white p-4 rounded shadow-lg max-h-[80vh] overflow-auto">
        {/* Scene Configuration */}
        <div className="mb-4">
          <h3 className="font-bold mb-2">Scene Settings</h3>
          <label className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={sceneConfig.showGrid}
              onChange={(e) => updateSceneConfig({ showGrid: e.target.checked })}
              className="mr-2"
            />
            Show Grid
          </label>
          <label className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={sceneConfig.snapToGrid}
              onChange={(e) => updateSceneConfig({ snapToGrid: e.target.checked })}
              className="mr-2"
            />
            Snap to Grid
          </label>
          <label className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={sceneConfig.showAxes}
              onChange={(e) => updateSceneConfig({ showAxes: e.target.checked })}
              className="mr-2"
            />
            Show Axes
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={showBones}
              onChange={(e) => setShowBones(e.target.checked)}
              className="mr-2"
            />
            Show Bones
          </label>
        </div>

        {/* Character Selection */}
        <div className="mb-4">
          <h3 className="font-bold mb-2">Character Selection</h3>
          <select
            className="w-full p-2 border rounded"
            value={selectedCharacterId || ''}
            onChange={(e) => setSelectedCharacterId(e.target.value)}
          >
            <option value="">Select Character</option>
            {Array.from(loadedCharacters.values()).map(char => (
              <option key={char.id} value={char.id}>
                {char.name}
              </option>
            ))}
          </select>
        </div>

        {/* Position Controls */}
        {selectedCharacterId && (
          <div className="mb-4">
            <h3 className="font-bold mb-2">Position Controls</h3>

            {/* X Position */}
            <div className="mb-2">
              <label className="block text-sm">X Position (Left/Right)</label>
              <div className="flex items-center gap-2">
                <input
                  type="number"
                  value={coordinates.x}
                  onChange={(e) => moveCharacter(selectedCharacterId, {
                    ...coordinates,
                    x: parseFloat(e.target.value)
                  })}
                  className="w-24 p-1 border rounded"
                />
                <div className="flex gap-1">
                  <button
                    className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={() => moveCharacter(selectedCharacterId, {
                      ...coordinates,
                      x: coordinates.x - SNAP_INCREMENT
                    })}
                  >
                    -10
                  </button>
                  <button
                    className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={() => moveCharacter(selectedCharacterId, {
                      ...coordinates,
                      x: coordinates.x + SNAP_INCREMENT
                    })}
                  >
                    +10
                  </button>
                </div>
              </div>
            </div>

            {/* Z Position */}
            <div className="mb-2">
              <label className="block text-sm">Z Position (Forward/Back)</label>
              <div className="flex items-center gap-2">
                <input
                  type="number"
                  value={coordinates.z}
                  onChange={(e) => moveCharacter(selectedCharacterId, {
                    ...coordinates,
                    z: parseFloat(e.target.value)
                  })}
                  className="w-24 p-1 border rounded"
                />
                <div className="flex gap-1">
                  <button
                    className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={() => moveCharacter(selectedCharacterId, {
                      ...coordinates,
                      z: coordinates.z - SNAP_INCREMENT
                    })}
                  >
                    -10
                  </button>
                  <button
                    className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={() => moveCharacter(selectedCharacterId, {
                      ...coordinates,
                      z: coordinates.z + SNAP_INCREMENT
                    })}
                  >
                    +10
                  </button>
                </div>
              </div>
            </div>

            {/* Rotation */}
            <div className="mb-2">
              <label className="block text-sm">Rotation (Degrees)</label>
              <div className="flex items-center gap-2">
                <input
                  type="number"
                  value={rotation.y}
                  onChange={(e) => moveCharacter(selectedCharacterId, coordinates, parseFloat(e.target.value))}
                  className="w-24 p-1 border rounded"
                />
                <div className="flex gap-1">
                  <button
                    className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={() => moveCharacter(selectedCharacterId, coordinates, rotation.y - 45)}
                  >
                    -45°
                  </button>
                  <button
                    className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={() => moveCharacter(selectedCharacterId, coordinates, rotation.y + 45)}
                  >
                    +45°
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Animation Controls */}
        <div className="mb-4">
          <h3 className="font-bold mb-2">Animations</h3>
          {loadingAnimations ? (
            <div>Loading animations...</div>
          ) : (
            <>
              {animations.map((anim, index) => (
                <button
                  key={index}
                  className="mb-2 w-full px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={() => playAnimation(anim)}
                >
                  {anim.name || `Animation ${index + 1}`}
                </button>
              ))}
              {isPlaying && (
                <button
                  className="w-full px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                  onClick={stopAnimation}
                >
                  Stop Animation
                </button>
              )}
            </>
          )}
        </div>

        {/* Bone Controls */}
        {showBones && (
          <div className="mb-4">
            <h3 className="font-bold mb-2">Bone Controls</h3>
            <select
              className="w-full mb-4 p-2 border rounded"
              value={selectedBone || ''}
              onChange={(e) => selectBone(e.target.value)}
            >
              <option value="">Select a bone</option>
              {bones.map(bone => (
                <option key={bone.name} value={bone.name}>
                  {bone.name}
                </option>
              ))}
            </select>

            {selectedBone && (
              <div className="space-y-2">
                <h4 className="font-bold">Manipulation Mode</h4>
                <div className="flex gap-2">
                  {['translate', 'rotate', 'scale'].map(mode => (
                    <button
                      key={mode}
                      className={`px-3 py-1 rounded ${
                        manipulationMode === mode ? 'bg-green-500' : 'bg-blue-500'
                      } text-white`}
                      onClick={() => updateManipulationMode(mode)}
                    >
                      {mode.charAt(0).toUpperCase() + mode.slice(1)}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {/* Material Controls */}
        <div className="mb-4">
          <h3 className="font-bold mb-2">Materials</h3>
          {materials.map((material, index) => (
            <div key={index} className="mb-2 flex items-center">
              <input
                type="color"
                value={`#${material.color.getHexString()}`}
                onChange={(e) => updateMaterialColor(material, e.target.value)}
                className="mr-2"
              />
              <span>Material {index + 1}</span>
            </div>
          ))}
        </div>

        {/* Position Display */}
        <div className="mt-4 p-2 bg-gray-100 rounded">
          <p className="text-sm font-bold">Current Position:</p>
          <p className="text-xs">X: {coordinates.x.toFixed(2)}</p>
          <p className="text-xs">Z: {coordinates.z.toFixed(2)}</p>
          <p className="text-xs">Rotation: {rotation.y.toFixed(1)}°</p>
        </div>
      </div>
    </div>
  );
};

export default KnightScene;