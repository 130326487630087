import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Loader2 } from 'lucide-react';
import 'aframe';

const PanoramaViewer = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [panoramas, setPanoramas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPanoramas = async () => {
      try {
        setIsLoading(true);
        const res = await fetch('/api/get_panoramas');
        if (!res.ok) throw new Error('Failed to fetch panoramas');
        const data = await res.json();
        setPanoramas(data.panoramas || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPanoramas();
  }, []);

  const navigatePanorama = (direction) => {
    setCurrentIndex((prevIndex) => {
      if (direction === 'next') {
        return prevIndex === panoramas.length - 1 ? 0 : prevIndex + 1;
      }
      return prevIndex === 0 ? panoramas.length - 1 : prevIndex - 1;
    });
  };

  if (error) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-100">
        <div className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-2 text-xl font-bold text-red-600">Error</h2>
          <p className="text-gray-700">{error}</p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-100">
        <div className="text-center">
          <Loader2 className="mx-auto h-8 w-8 animate-spin text-blue-500" />
          <p className="mt-2 text-gray-700">Loading panoramas...</p>
        </div>
      </div>
    );
  }

  if (panoramas.length === 0) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-100">
        <div className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-2 text-xl font-bold">No Panoramas Found</h2>
          <p className="text-gray-700">Please add some panoramic images to get started.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative h-screen w-screen">
      <a-scene embedded>
        <a-sky
          src={panoramas[currentIndex]}
          animation="property: rotation; to: 0 360 0; loop: true; dur: 100000; easing: linear"
        />
        <a-camera>
          <a-entity
            raycaster="objects: .clickable"
            cursor="fuse: false; rayOrigin: mouse;"
          />
        </a-camera>
      </a-scene>

      {/* Navigation Controls */}
      <div className="absolute bottom-4 left-1/2 flex -translate-x-1/2 transform space-x-4">
        <button
          onClick={() => navigatePanorama('prev')}
          className="rounded-full bg-white p-2 shadow-lg transition-transform hover:scale-110"
          aria-label="Previous panorama"
        >
          <ChevronLeft className="h-6 w-6 text-gray-800" />
        </button>
        <button
          onClick={() => navigatePanorama('next')}
          className="rounded-full bg-white p-2 shadow-lg transition-transform hover:scale-110"
          aria-label="Next panorama"
        >
          <ChevronRight className="h-6 w-6 text-gray-800" />
        </button>
      </div>

      {/* Image Counter */}
      <div className="absolute bottom-4 right-4 rounded-full bg-white px-3 py-1 text-sm shadow-lg">
        {currentIndex + 1} / {panoramas.length}
      </div>
    </div>
  );
};

export default PanoramaViewer;